import React, { useEffect } from 'react';

const GoogleTagManager = () => {
  useEffect(() => {
    // Function to load the gtag script
    const loadGTag = () => {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=AW-11414356944`;
      document.body.appendChild(script);

      // Initialize the gtag window object
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'AW-11414356944');
    };
    loadGTag();
  }, []);

  return null; // This component doesn't render anything
};

export default GoogleTagManager;
