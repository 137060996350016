import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { withResizeDetector } from 'react-resize-detector';

const Sidebar = () => {
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(true);  // By default, it's expanded
    const [updateInfoExpanded, setUpdateInfoExpanded] = useState(true);  // For Update Info sub-sections
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [email,setEmail] = useState(localStorage.getItem('user_email') || '');
    const [fullName,setFullName] = useState(localStorage.getItem('user_full_name') || '');
    const [userID,setUserID] = useState(localStorage.getItem('user_id') || '');
    

    var url = `https://getfitter.lemonsqueezy.com/checkout/buy/cab108d7-fd40-48e4-969d-a8feaeaf70a7?checkout[custom][user_id]=${userID}&checkout[email]=${email}&checkout[name]=${fullName}`

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const signOut = async () => {
        const { error } = await supabase.auth.signOut();
        if (error) {
            console.log('Error logging out:', error.message);
        } else {
            console.log('User logged out successfully');
            navigate('/signin');
        }
    };

    return (
        <div className={`h-screen fixed top-0 left-0 shadow-lg transition-all duration-300 border-r border-gray-700 ${isExpanded ? 'w-64' : 'w-20'} bg-gray-900 text-gray-100 ${isMobile ? 'hidden' : ''} flex-shrink-0`}>
            <div className="p-3 flex flex-col h-full">
            <a href="/" className="mb-6"> 
                    <img src="https://raw.githubusercontent.com/prudhvireddym/getfitter-logos/main/getfitterlogo192.png" alt="Logo" className={`h-15 w-15 mx-auto my-4 ${!isExpanded ? 'hidden' : ''}`} />
                <h1 className={`text-2xl font-semibold mb-4 text-center ${!isExpanded ? 'hidden' : ''}`}>GetFitterAI</h1>
            </a>


                <nav>
                        <ul className="space-y-4 ">
                            <li>
                                <a href="/diet" className="flex items-center space-x-2 hover:bg-gray-700 p-2 rounded">
                                    {isExpanded && <span className="material-icons-outlined">My Diet</span>}
                                </a>
                                {isExpanded && <hr className="my-2 border-t border-gray-700"/>}
                            </li>
                            <li>
                                <a href="/workouts" className="flex items-center space-x-2 hover:bg-gray-700 p-2 rounded">
                                    {isExpanded && <span className="material-icons-outlined">My Fitness</span>}
                                </a>
                                {isExpanded && <hr className="my-2 border-t border-gray-700"/>}
                            </li>
                            <li>
                                <a href="/saved" className="flex items-center space-x-2 hover:bg-gray-700 p-2 rounded">
                                    {isExpanded && <span className="material-icons-outlined">My Saved Plans</span>}
                                </a>
                                {isExpanded && <hr className="my-2 border-t border-gray-700"/>}
                            </li>
                            <li>
                                <a href="/beginnerguide" className="flex items-center space-x-2 hover:bg-gray-700 p-2 rounded">
                                    {isExpanded && <span className="material-icons-outlined">Beginner Guide</span>}
                                </a>
                                {isExpanded && <hr className="my-2 border-t border-gray-700"/>}
                            </li>
                            <li>
                                <a href="/updatemyinfo" className="flex items-center space-x-2 hover:bg-gray-700 p-2 rounded">
                                    {isExpanded && <span className="material-icons-outlined">Settings</span>}
                                </a>
                            </li>
                            {/* <li>
                                    <a 
                                        href={url}
                                        className="flex items-center space-x-2 hover:bg-gray-700 p-2 rounded"
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                    >
                                        {isExpanded && <span className="material-icons-outlined">Get Pro</span>}
                                    </a>
                                </li> */}
                            {/* Add more options as needed */}
                        </ul>
                    </nav>

                {/* Sign Out Button */}
            </div>
        </div>

    );
    
    
}

export default Sidebar;