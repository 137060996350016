import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom'; 

const CheckmarkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-check text-green-500"
    style={{ height: '1em', width: '1em' }}
  >
    <polyline points="20 6 9 17 4 12"></polyline>
  </svg>
);

const PackageOption = ({ title, features, originalPrice, discountedPrice, mostPopular, bestValue, onSelect }) => (
  <div className={`bg-slate-900 p-4 rounded-lg shadow-md mb-4 ${mostPopular ? 'border-l-4 border-orange-500' : ''} ${bestValue ? 'border-l-4 border-blue-300' : ''}`}>
    {mostPopular && <div className="text-right text-sm font-semibold text-orange-500">Most popular 🔥</div>}
    {bestValue && <div className="text-right text-sm font-semibold text-blue-300">Best value 💎</div>}
    <h3 className="font-bold text-lg mb-2 text-gray-100">{title}</h3>
    <ul className="list-none space-y-2 mb-4">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center text-gray-300">
          <CheckmarkIcon />
          <span className="ml-2">{feature}</span>
        </li>
      ))}
    </ul>
    <div className="flex justify-between items-center mt-4 text-gray-300">
      <div>
        <span className="line-through mr-2">${originalPrice}</span>
        <span className="text-lg font-bold">${discountedPrice}</span>
      </div>
      <button 
        className="bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 text-white font-bold py-2 px-4 rounded hover:bg-slate-600" 
        onClick={onSelect}
      >
        Select
      </button>
    </div>
  </div>
);

const PricingOverlay = ({ isVisible, onClose }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [email, setEmail] = useState(localStorage.getItem('user_email') || '');
  const [fullName, setFullName] = useState(localStorage.getItem('user_full_name') || '');
  const [userID, setUserID] = useState(localStorage.getItem('user_id') || '');

  var url = `https://getfitter.lemonsqueezy.com/checkout/buy/cab108d7-fd40-48e4-969d-a8feaeaf70a7?checkout[custom][user_id]=${userID}&checkout[email]=${email}&checkout[name]=${fullName}`;

  const handleUpgradeClick = () => {
    window.open(url, '_blank');
};

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!isVisible) return null;

  // Adjustments for non-sidebar coverage
  const overlayStyle = {
    left: isMobile ? '0px' : '256px', // Assuming the sidebar width is 256px
    width: isMobile ? '100%' : `calc(100% - 256px)`, // Full width minus sidebar width
    bottom: isMobile ? '50px' : '0px', // Adjust for bottom navbar height on mobile
  };
  const overlayOnclick = (e) => {
    console.log("Overlay clicked");
    e.stopPropagation(); // This stops the click event from bubbling up to the backdrop
  };

  // Overlay content
  const overlayContent = (
    <div className="fixed inset-0 z-50 flex items-center justify-center" style={{ ...overlayStyle, pointerEvents: 'auto' }} onClick={overlayOnclick}>
      <div className="absolute inset-0 bg-black bg-opacity-75 backdrop-blur-sm" ></div>
      <div className={`bg-gradient-to-r from-slate-900 to-slate-800 p-4 rounded-lg flex flex-col items-center space-y-4 ${isMobile ? 'w-full max-w-xs' : 'w-full max-w-md'} overflow-y-auto`} style={{ zIndex: 60 }}>
        <h1 className="text-white font-bold text-2xl mb-2">Select a package</h1>
        <div className="flex items-center space-x-2">
          <CheckmarkIcon />
          <h2 className="text-white font-bold text-xl">7 days free trial, cancel anytime</h2>
        </div>
        <div className="w-full">
          <PackageOption
            title="Pro (Paid monthly)"
            features={['Three Different Meal types', 'Unlimited no of meal resets', 'Unlimited Replace Meals', 'Unlimited workout resets', 'Unlimited Replace Workouts', 'Priority support']}
            originalPrice="9.99"
            discountedPrice="4.99"
            mostPopular
            onSelect={handleUpgradeClick}
          />
          <PackageOption
            title="PRO (Paid yearly)"
            features={['Three Different Meal types', 'Unlimited no of meal resets', 'Unlimited Replace Meals', 'Unlimited workout resets', 'Unlimited Replace Workouts', 'Priority support']}
            originalPrice="99.99"
            discountedPrice="49.99"
            bestValue
            onSelect={handleUpgradeClick}
          />
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(
    overlayContent,
    document.getElementById('overlay-root') // Make sure you have a div with id="overlay-root" in your index.html
  );
};

export default PricingOverlay;
