import React from 'react';
import Footer from '../Navigation/Footer';
import discordImage from '../../assets/main/discord.png';


const ContactPage = () => {
    return (
        <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-4 space-y-4">
            {/* Header */}
            <header className="w-full text-center mb-12">
                <h1 className="text-[2.5rem] mt-2 leading-none sm:text-3xl tracking-tight font-bold text-gray-100 dark:text-white">
                    Contact Us
                </h1>
            </header>

            {/* Content */}
            <div className="space-y-4 bg-slate-900 p-8 rounded-lg shadow-lg max-w-5xl w-full mx-auto mb-8 text-gray-300">
                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Get in Touch</h2>
                    <p>I would love to hear from you! Whether you have a question about our services, pricing, or anything else, our team is ready to answer all your questions.</p>
                </section>

                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Contact Information</h2>
                    <p>Email: prudhvisbusiness@gmail.com</p>
                    {/* Discord Support Link */}
                    <div className="mt-6 text-center">
                    <a href="https://discord.gg/wfbNfd5PFk" target="_blank" rel="noopener noreferrer">
                        <img src={discordImage} alt="Join us on Discord" className="w-23 h-16 hover:opacity-80 transition-opacity duration-200" />
                    </a>
                    </div>
                </section>

                {/* <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Business Hours</h2>
                    <p>Monday - Friday: 9:00 AM to 5:00 PM</p>
                    <p>Saturday - Sunday: Closed</p>
                </section>

                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Social Media</h2>
                    <p>Follow us on social media to stay updated on our latest offers and promotions.</p>
                    <p>
                        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white">Facebook</a>,
                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white ml-2">Twitter</a>,
                        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white ml-2">Instagram</a>
                    </p>
                </section> */}
            </div>

            <Footer />
        </div>
    );
};

export default ContactPage;
