import React from 'react';
import aiImage from '../../assets/main/trainer.png';
import closeImage from '../../assets/main/close.png';

const FloatingButton = ({ onClick, isChatOpen }) => {
  return (
    <button 
      className="fixed bottom-7 right-2 bg-transparent border-none rounded-full p-4 text-lg cursor-pointer transform transition-transform duration-300 active:scale-90 z-10" 
      onClick={onClick}
      style={{ width: '100px', height: '100px' }}
    >
      <img 
        src={isChatOpen ? closeImage : aiImage} 
        alt="AI" 
        className="w-full h-full object-cover" 
      />
    </button>
  );
};

export default FloatingButton;
