import { FaFacebookF } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import React, { useEffect } from 'react';

const Footer = () => {
    return (
        // Wrap the returned JSX inside parentheses
        <footer className="w-full bg-slate-900 p-4 mx-auto h-40">
            <div className="container mx-auto text-center">
                <div className="flex justify-center items-center space-x-4 mb-4">
                    <a href="/about" className="text-gray-300 hover:text-white">About Us</a>
                    <a href="/contact" className="text-gray-300 hover:text-white">Contact</a>
                    <a href="/termsofservice" className="text-gray-300 hover:text-white">Terms of Service</a>
                    <a href="/privacypolicy" className="text-gray-300 hover:text-white">Privacy Policy</a>
                </div>
                <div className="flex justify-center items-center space-x-4 mb-4">
                    <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                        <FaFacebookF className="text-gray-300 hover:text-white" />
                    </a>
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                        <FaTwitter className="text-gray-300 hover:text-white" />
                    </a>
                    <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                        <FaInstagram className="text-gray-300 hover:text-white" />
                    </a>
                    <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                        <FaLinkedinIn className="text-gray-300 hover:text-white" />
                    </a>
                </div>
                <p className="text-gray-300 text-sm">&copy; {new Date().getFullYear()} GetFitterAI. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;