// src/components/NavButtons.js
import React from 'react';
import { useLocation } from 'react-router-dom';

const NavButtons = () => {
  const location = useLocation();
  return (
    <div className="flex flex-wrap justify-center space-x-2 sm:space-x-4 my-2">
      {[
        { label: 'Info', url: '/updatemyinfo' },
        { label: 'Workout', url: '/updatemyworkoutinfo' },
        { label: 'Diet', url: '/updatemydietinfo' },
        { label: 'Subscription', url: '/othersettings' },
        { label: 'Saved', url: '/saved' }
      ].map(button => (
        <a
          key={button.label}
          href={button.url}
          className={`px-4 py-1 sm:px-6 sm:py-2 rounded-full ${location.pathname === button.url ? 'bg-gradient-to-r from-blue-700 via-blue-800 to-gray-900 text-white' : 'bg-slate-900 text-gray-300 hover:bg-slate-800'} inline-block text-center`}
        >
          {button.label}
        </a>
      ))}
    </div>
  );
};

export default NavButtons;
