import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Navigation/Footer';

const AboutPage = () => {
    return (
        <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-4 space-y-4">
            {/* Header */}
            <header className="w-full text-center mb-12">
                <h1 className="text-[2.5rem] mt-2 leading-none sm:text-3xl tracking-tight font-bold text-gray-100 dark:text-white">
                    About
                </h1>
            </header>

            {/* Content */}
            <div className="space-y-4 bg-slate-900 p-8 rounded-lg shadow-lg max-w-5xl w-full mx-auto mb-8 text-gray-300">
                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Who am I</h2>
                    <p>Hello all, this is Prudhvi, I am a full stack software engineer behind getfitter.ai, currently in Dallas, Texas.</p>
                </section>

                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">My Mission</h2>
                    <p>When I was very new to the fitness industry, You have a lot to understand to get started, but soon realize that personal trainers are really expensive. My mission is to make fitness affordable for anyone who wants to get into it.</p>
                </section>

                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Our Vision</h2>
                    <p>Our vision at GetFitterAI is to bridge the gap between high-quality fitness training and affordability. By leveraging cutting-edge technology, we aim to provide personalized fitness plans and guidance that cater to individual needs and goals, making a healthier lifestyle accessible to all.</p>
                </section>

                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Contact Information</h2>
                    <p>If you have any questions or want to get in touch, feel free to contact us at:</p>
                    <p>Email: support@getfitter.ai</p>
                </section>
            </div>

            <Footer />
        </div>
    );

};

export default AboutPage;
