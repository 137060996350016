

export const calculateTDEE = (unit,age,gender,weight,heightInFeet,heightInInches,activity,goal) => {
    const LBS_TO_KGS = 2.205;

    let adjustedWeightInKgs = unit === 'metric' ? weight : weight / LBS_TO_KGS;

    console.log(`Adjusted weight in KGS: ${adjustedWeightInKgs}, unit: ${unit}, weight: ${weight}`);

    

    let totalHeightInInches = (parseInt(heightInFeet) * 12) + parseInt(heightInInches);

    let adjustedHeightInCM = totalHeightInInches * 2.54;

    console.log(`Adjusted height in CM: ${adjustedHeightInCM}, Total Height in Inches: ${totalHeightInInches} height in feet: ${heightInFeet}, height in inches: ${heightInInches}`);

    // console.log(`10 * ${parseFloat(adjustedWeightInKgs)} + 6.25 * ${parseFloat(adjustedHeightInCM)} - 5 * ${parseFloat(age)}`)
    
    let bmr = Math.round(10 * parseFloat(adjustedWeightInKgs) + 6.25 * parseFloat(adjustedHeightInCM) - 5 * parseFloat(age));

    if (gender === 'male') {
        bmr += 5;
    } else if (gender === 'female') {
        bmr -= 161;
    }
    console.log(`Calculated BMR: ${bmr}`)
    
    const calculatedTdee = bmr * parseFloat(activity);
    console.log(`Calculated TDEE: ${calculatedTdee}`);

    let goalTDEE = calculatedTdee;  // Default value

    if (goal === 'Lose Weight') {
        goalTDEE = calculatedTdee - 500; // Average between 400-500
    } else if (goal === 'Gain Muscle Mass') {
        goalTDEE = calculatedTdee + 500;
    }


    let returnPayload = {
        user_goal_tdee: goalTDEE.toFixed(2),
        user_tdee : calculatedTdee.toFixed(2),
        user_weight_in_kgs: adjustedWeightInKgs,
        user_height_in_cm: adjustedHeightInCM, 
    }

    return returnPayload;

};

