import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Navigation/Footer';

const BeginnerGuide = () => {
    const [expandedSection, setExpandedSection] = useState(null);

    const toggleSection = (section) => {
        if (expandedSection === section) {
            setExpandedSection(null);
        } else {
            setExpandedSection(section);
        }
    };

    return (
        <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-4 space-y-4">
        
        {/* Header */}
        <header className="w-full text-center mb-12">
            <h1 className="text-[2.5rem] mt-2 leading-none sm:text-3xl tracking-tight font-bold text-gray-100 dark:text-white">
                Beginner Fitness Guide
            </h1>
        </header>

        {/* Content */}
        <div className="bg-slate-900 p-4 rounded-lg shadow-lg max-w-5xl w-full mx-auto mb-8 text-gray-300 space-y-4">
            
                {/* Section Wrapper */}
                <div className="p-4 rounded-lg shadow-md" onClick={() => toggleSection('measuring')}>
                    <h2 className="text-xl font-bold mb-4 cursor-pointer flex justify-between items-center mb-30">
                            Measuring Food
                            <span>&#x25BC;</span> {/* Down arrow */}
                        </h2>
                        {expandedSection === 'measuring' && (
                            <ul className="list-disc pl-5">
                                <li>While some may find measuring food tedious, and ridiculous.</li>
                                <li>It is undeniably one of the most scientifically validated methods for achieving fitness goals.</li>
                                <li>It's recommended to measure food in its uncooked state for accuracy.</li>
                                <li>To measure portions precisely, consider using a scale like <a href="https://amzn.to/3S7Ge7t" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700"> this one</a> (affliliate link).</li> {/* ADDED text-blue-500 hover:text-blue-700 */}
                                <li>Opt for consistent, simple meals over elaborate preps for sustainable results.</li>
                            </ul>
                        )}
                    </div>

                <div className="p-4 rounded-lg shadow-md" onClick={() => toggleSection('measuringyourstats')}>
                    <h2 className="text-xl font-bold mb-4 cursor-pointer flex justify-between items-center mb-30">
                        Measuring Your Stats
                        <span>&#x25BC;</span> {/* Down arrow */}
                    </h2>
                    {expandedSection === 'measuringyourstats' && (
                        <ul className="list-disc pl-5">
                            <li>To ensure consistency and accuracy in your data, make it a habit to record measurements at the same time of day and under comparable conditions, especially for weight metrics.</li>
                            <li>For optimal weight tracking, consider using a <a href="https://amzn.to/3tGLqFn" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">high-quality weight scale like this</a> (affliliate link). The best time to record your weight is early in the morning, pre-food or drink, and after using the restroom.</li> {/* ADDED text-blue-500 hover:text-blue-700 */}
                            <li>Gain deeper insights into your fitness journey by regularly tracking body dimensions like weight, waist, chest, and hips with a <a href="https://amzn.to/3S7mGAi" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">measuring tape like this</a> (affliliate link).</li> {/* ADDED text-blue-500 hover:text-blue-700 */}
                            <li>Stay tuned! We're excited to introduce a dedicated tracking screen soon to enhance your progress monitoring.</li>
                        </ul>
                    )}
                </div>


                <div className="p-4 rounded-lg shadow-md" onClick={() => toggleSection('hydration')}>
                    <h2 className="text-xl font-bold mb-4 cursor-pointer flex justify-between items-center mb-30">
                        Hydration and Nutrition 
                        <span>&#x25BC;</span> {/* Down arrow */}
                    </h2>
                    {expandedSection === 'hydration' && (
                        <ul className="list-disc pl-5">
                            <li>Stay hydrated. Aim for at least 2-3 liters of water daily, adjusting based on physical activity and climate.</li>
                            <li>Before workouts, consider a small caffeine boost from black coffee or green tea. It might enhance performance.</li>
                            <li>Multivitamins can be a helpful addition to bridge any dietary gaps, but it's best to consult with a healthcare provider.</li>
                            <li>Omega-3 fatty acids, like those in fish oil or flaxseed, support heart and brain health.</li>
                            <li>Fermented foods or beverages like diluted apple cider vinegar can aid digestion. Always dilute and consume in moderation.</li>
                            <li>Psyllium husk can promote digestive regularity. Make sure to drink plenty of water with it.</li>
                            <li>Limit artificial sweeteners and sodas. Opt for herbal teas or natural beverages instead.</li>
                            <li>Lemon water can be a refreshing and vitamin C-rich drink option.</li>
                        </ul>
                    )}
                </div>

                <div className="p-4 rounded-lg shadow-md" onClick={() => toggleSection('workout')}>
                    <h2 className="text-xl font-bold mb-4 cursor-pointer flex justify-between items-center mb-30">
                        Workout Basics 
                        <span>&#x25BC;</span> {/* Down arrow */}
                    </h2>
                    {expandedSection === 'workout' && (
                        <ul className="list-disc pl-5">
                            <li>Always start with a warm-up. It prepares your body for the main exercise and reduces injury risk.</li>
                            <li>As a beginner, focus on compound exercises like squats, push-ups, and lunges. They target multiple muscle groups at once.</li>
                            <li>Remember to cool down post-workout. It helps in recovery and reduces muscle stiffness.</li>
                            <li>Rest is crucial. Ensure you're getting adequate sleep and giving muscles time to recover between workouts.</li>
                            <li>Introduce variations and increase intensity gradually to prevent plateaus and continue progressing.</li>
                        </ul>
                    )}
                </div>

                <div className="p-4 rounded-lg shadow-md" onClick={() => toggleSection('consistency')}>
                    <h2 className="text-xl font-bold mb-4 cursor-pointer flex justify-between items-center mb-30">
                        Consistency Over Intensity 
                        <span>&#x25BC;</span> {/* Down arrow */}
                    </h2>
                    {expandedSection === 'consistency' && (
                        <p>Consistency in your efforts is more important than the intensity of a few sessions. Setting realistic goals and sticking to them daily will lead to long-term success. Remember, it's a marathon, not a sprint.</p>
                    )}
                </div>

                <div className="p-4 rounded-lg shadow-md" onClick={() => toggleSection('injuriesAllergies')}>
                    <h2 className="text-xl font-bold mb-4 cursor-pointer flex justify-between items-center mb-30">
                        Injuries and Allergies
                        <span>&#x25BC;</span> {/* Down arrow */}
                    </h2>
                    {expandedSection === 'injuriesAllergies' && (
                        <p>When in doubt about potential injuries or allergies, it's crucial to consult with healthcare professionals. They can provide personalized advice and safety measures tailored to your specific circumstances.</p>
                    )}
                </div>



            </div>

            <Footer />
        </div>
    );
};

export default BeginnerGuide;
