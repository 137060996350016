import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import invert from 'lodash/invert';
import { supabase } from '../../supabaseClient';
import { calculateTDEE } from '../Utils/Functions/CalculateTDEE';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import dispatchAlert from '../Utils/Functions/DispatchAlert';
import NavButtons from '../Utils/Components/NavButtons';

const UpdateUserInfo = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const activityLevel = {
        "1.2": "Sedentary (office job)",
        "1.375": "Light Exercise (1-2 days/week)",
        "1.55": "Moderate Exercise (3-5 days/week)",
        "1.725": "Heavy Exercise (6-7 days/week)",
        "1.9": "Athlete (2x per day)"
    };

    const goal = localStorage.getItem('user_fitness_goal');
    const [email, setEmail] = useState(localStorage.getItem('user_email') || '');
    const [age, setAge] = useState(localStorage.getItem('user_age') || '');
    const [gender, setGender] = useState(localStorage.getItem('user_gender') || '');

    const [weightInKgs, setWeightInKgs] = useState(localStorage.getItem('user_weight_in_kgs') || '');
    const [weightInLbs, setWeightInLbs] = useState(Math.round(weightInKgs * 2.205));

    const [height, setHeight] = useState(localStorage.getItem('user_height_in_cm') || '');
    const [activity, setActivity] = useState(localStorage.getItem('user_activity_level') || '1.375');

    const [unit, setUnit] = useState('metric');
    const [heightInFeet, setHeightInFeet] = useState('4');
    const [heightInInches, setHeightInInches] = useState('0');
    const [tdee, setTdee] = useState(localStorage.getItem('user_tdee') || '');
    const [goalTDEE, setGoalTDEE] = useState(localStorage.getItem('user_goal_tdee') || '');
    const feetOptions = Array.from({ length: 5 }, (_, i) => i + 4);
    const inchesOptions = Array.from({ length: 12 }, (_, i) => i);

    const [loading, setLoading] = useState(false);

    const handleWeightChange = (value) => {
        if (unit === 'metric') {
            setWeightInKgs(value);
            setWeightInLbs(Math.round(value * 2.205));
        } else {
            setWeightInLbs(value);
            setWeightInKgs(Math.round(value / 2.205));
        }
    };

    const cmToFeetAndInches = (cm) => {
        const inches = cm / 2.54;
        const feet = Math.floor(inches / 12);
        const remainingInches = Math.round(inches % 12);
        return [feet, remainingInches];
    };

    useEffect(() => {
        if (height) {
            let value = cmToFeetAndInches(parseFloat(height));
            setHeightInFeet(value[0]);
            setHeightInInches(value[1]);
        }
    }, [height]);

    const setLocalVariables = (payload) => {
        for (let key in payload) {
            localStorage.setItem(key, payload[key]);
        }
    };

    const makeApiCall = async (payload) => {
        try {
            setLoading(true);
            const { data: { session }, } = await supabase.auth.getSession();

            const response = await fetch('https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/write_user_data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session['access_token']}`
                },
                body: JSON.stringify(payload),
            });

            const result = await response.json();
            if (response.status === 200) {
                dispatchAlert(dispatch, 'success', 'Changes updated successfully');
            } else {
                dispatchAlert(dispatch, 'error', 'Failed to update changes');
            }
            console.log('Data sent to localhost:8000', result);
            setLoading(false);
        } catch (fetchError) {
            setLoading(false);
            console.error('Error sending data to localhost:8000', fetchError);
            dispatchAlert(dispatch, 'error', 'An error occurred. Please try again.');
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        console.log("Form submitted");
        console.log(`current email: ${email}, current age: ${age}, gender : ${gender}, weight: ${weightInKgs}, unit: ${unit}, height in feet: ${heightInFeet}, height in inches: ${heightInInches}, activity: ${activity}, goal: ${goal}`);
        
        // Validate age
        const ageNumber = parseInt(age, 10);
        if (isNaN(ageNumber) || ageNumber < 13 || ageNumber > 85) {
            dispatchAlert(dispatch, 'error', 'Age must be between 13 and 85.');
            return;
        }

        // Validate other fields if necessary

        var res = calculateTDEE('metric', ageNumber, gender, weightInKgs, heightInFeet, heightInInches, activity, goal);
        console.log(res);
        setLocalVariables(res);
        setTdee(res.user_tdee);
        setGoalTDEE(res.user_goal_tdee);

        const finalPayloadToUpdate = {
            user_email: email,
            user_age: ageNumber, // Ensure age is stored as a number
            user_gender: gender,
            user_weight_in_kgs: weightInKgs,
            user_height_in_cm: height,
            user_activity: activity,
            user_tdee: res.user_tdee,
            user_goal_tdee: res.user_goal_tdee,
            write_table: 'my_users'
        };

        makeApiCall(finalPayloadToUpdate);
    };

    const clearLocalStorageData = () => {
        console.log("clearLocalStorageData called");
        localStorage.clear();
    };

    const signOut = async () => {
        const { error } = await supabase.auth.signOut();
        if (error) {
            console.log('Error logging out:', error.message);
        } else {
            clearLocalStorageData();
            console.log('User logged out successfully');
            navigate('/signin');
        }
    };

    return (
        <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-2 sm:p-4 space-y-2 sm:space-y-4">
            <NavButtons />

            {loading && 
                <div className="flex flex-col justify-center items-center h-screen space-y-4">
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <p className="text-white">Updating Info.. 🪄✨</p>
                </div>
            }

            <h1 className="text-xl mt-2 leading-none sm:text-3xl tracking-tight font-bold text-gray-100 dark:text-white text-center">
                Let's update your daily calorie needs
            </h1>
            {tdee && goalTDEE && (
                <p className="text-lg text-gray-300 text-center">
                    Your current Calories: {tdee} cal | Your target Calories Should Be Around: {goalTDEE} cal
                </p>
            )}

            <form onSubmit={handleFormSubmit} className="space-y-4 bg-slate-900 p-4 sm:p-8 rounded-lg shadow-lg w-full sm:w-2/3 lg:w-1/3 mb-8"> 
                <div className="flex flex-col space-y-2">
                    <label htmlFor="email" className="text-lg font-medium text-gray-100">Email:</label>
                    <input 
                        type="email" 
                        id="email" 
                        placeholder="Email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        className="py-2 px-4 border rounded" 
                        required 
                    />
                </div>

                <div className="flex flex-col space-y-2">
                    <label htmlFor="age" className="text-lg font-medium text-gray-100">Age:</label>
                    <input 
                        type="number" 
                        id="age" 
                        placeholder="Age" 
                        value={age} 
                        onChange={(e) => {
                            setAge(e.target.value);
                        }} 
                        onWheel={(e) => e.target.blur()} 
                        min="13" 
                        max="85"
                        className="py-2 px-4 border rounded" 
                        required 
                    />
                </div>

                <div className="flex flex-col space-y-2">
                    <label htmlFor="gender" className="text-lg font-medium text-gray-100">Gender:</label>
                    <select 
                        id="gender" 
                        value={gender} 
                        onChange={(e) => setGender(e.target.value)} 
                        className="py-2 px-4 border rounded" 
                        required
                    >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        {/* Add more options if needed */}
                    </select>
                </div>

                <div className="flex flex-col space-y-2">
                    <label htmlFor="unit" className="text-lg font-medium text-gray-100">Unit:</label>
                    <select 
                        id="unit" 
                        value={unit} 
                        onChange={(e) => setUnit(e.target.value)} 
                        className="py-2 px-4 border rounded" 
                        required
                    >
                        <option value="metric">Metric (Kg's)</option>
                        <option value="imperial">Imperial (Pounds)</option>
                    </select>
                </div>

                <div className="flex flex-col space-y-2">
                    <label htmlFor="weight" className="text-lg font-medium text-gray-100">
                        Weight {unit === 'metric' ? '(in kg)' : '(in lbs)'}:
                    </label>
                    <input 
                        type="number" 
                        id="weight" 
                        placeholder={unit === 'metric' ? 'Weight in kg' : 'Weight in lbs'} 
                        value={unit === 'metric' ? weightInKgs : weightInLbs} 
                        onChange={(e) => handleWeightChange(e.target.value)}
                        onWheel={(e) => e.target.blur()}
                        className="py-2 px-4 border rounded" 
                        required 
                    />      
                </div>

                <div className="flex flex-col space-y-2">
                    <label className="text-lg font-medium text-gray-100">Height:</label>
                    <div className="flex space-x-2">
                        <select 
                            id="heightFeet" 
                            value={heightInFeet} 
                            onChange={(e) => setHeightInFeet(e.target.value)} 
                            className="py-2 px-4 border rounded w-1/2" 
                            required
                        >
                            <option value="">Feet</option>
                            {feetOptions.map(foot => (
                                <option key={foot} value={foot}>{foot} ft</option>
                            ))}
                        </select>

                        <select 
                            id="heightInches" 
                            value={heightInInches} 
                            onChange={(e) => setHeightInInches(e.target.value)} 
                            className="py-2 px-4 border rounded w-1/2" 
                            required
                        >
                            <option value="">Inches</option>
                            {inchesOptions.map(inch => (
                                <option key={inch} value={inch}>{inch} in</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="flex flex-col space-y-2">
                    <label htmlFor="activity" className="text-lg font-medium text-gray-100">
                        Activity Level (include workout days selected):
                    </label>
                    <select 
                        id="activity" 
                        value={activity} 
                        onChange={(e) => setActivity(e.target.value)} 
                        className="py-2 px-4 border rounded w-full" 
                        required
                    >
                        <option value="">Select Activity Level</option>
                        {Object.keys(activityLevel).map(level => (
                            <option key={level} value={level}>{activityLevel[level]}</option>
                        ))}
                    </select>
                </div>

                <button 
                    type="submit" 
                    className="py-2 px-4 bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 text-white rounded w-full hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
                >
                    Save Changes
                </button>
            </form>

            <hr className="mt-4 mb-4" /> {/* Horizontal rule */}

            <p 
                className="text-red-500 underline cursor-pointer text-center mt-4" 
                onClick={signOut}
            >
                Sign out
            </p>
        </div>
    );
};

export default UpdateUserInfo;
