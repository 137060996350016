import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideAlert } from '../../../features/alertSlice';

function MyAlert() {
  const dispatch = useDispatch();
  const { type, message, isVisible } = useSelector(state => state.alert);

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        dispatch(hideAlert());
      }, 15000); // 15 seconds

      return () => clearTimeout(timer);
    }
  }, [isVisible, dispatch]);

  if (!isVisible) return null;

  const bgColorClass = {
    error: 'bg-red-500 text-white',
    alert: 'bg-yellow-300 text-gray-900',
    success: 'bg-green-500 text-white'
  }[type] || 'bg-white text-gray-900';

  return (
    <div className={`fixed top-0 left-0 w-full p-2 sm:p-4 rounded-b-md shadow-md ${bgColorClass} z-50 flex justify-center items-center`}>
      <span className="flex-1 text-center">{message}</span>
      <span 
        className="ml-4 cursor-pointer text-xl font-bold"
        onClick={() => dispatch(hideAlert())}
        >
            &times;
        </span>
    </div>
  );
}

export default MyAlert;
