import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import dispatchAlert from '../Utils/Functions/DispatchAlert'
import NavButtons from '../Utils/Components/NavButtons'

const UpdateDietInfo = () => {
  const location = useLocation();
  const dispatch  = useDispatch();

  const [loading, setLoading] = useState(false);

  // Load initial values from local storage
  const [diet, setDiet] = useState(localStorage.getItem('user_diet_preference') || 'standard');
  const [foodType, setFoodType] = useState(localStorage.getItem('user_food_preference') || 'American');
  const [userFavFood, setUserFavFood] = useState(localStorage.getItem('user_fav_food') || '');

  const [user_allergies, setUserAllergies] = useState(localStorage.getItem('user_allergies') || '');
  const storedSelectedProtein = JSON.parse(localStorage.getItem('user_preferred_protein_sources')) || [];

  const [selectedProtein, setSelectedProtein] = useState(storedSelectedProtein); 

  const proteinSources = ['Poultry', 'Farm Meat', 'Seafood', 'Eggs', 'Tofu', 'Beans', 'Lentils', 'Nuts', 'Seeds', 'Dairy'];


  const toggleProtein = (protein) => {
    setSelectedProtein(prevProtein => {
      if (prevProtein.includes(protein)) {
        return prevProtein.filter(d => d !== protein);
      } else {
        return [...prevProtein, protein];
      }
    });
  };

    
    const saveChanges = async () => {
      setLoading(true);
      // Update local storage
      localStorage.setItem('user_food_preference', foodType);
      localStorage.setItem('user_preferred_protein_sources', JSON.stringify(selectedProtein));
      localStorage.setItem('user_fav_food', userFavFood);
      localStorage.setItem('user_allergies', user_allergies);
      localStorage.setItem('user_diet_preference', diet);
  
      // Create payload for API call
      const payload = {
        user_food_preference: foodType,
        user_allergies: user_allergies,
        user_diet_preference: diet,
        write_table:'my_users'
      };
  
      // Make API call
      try {
        const {data: { session },} = await supabase.auth.getSession()
  
        const response = await fetch('https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/write_user_data', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session['access_token']}`
          },
          body: JSON.stringify(payload),
        });
  
        const result = await response.json();
        if(response.status === 200) {
          dispatchAlert(dispatch,'success','Changes updated successfully')
      } else {
          dispatchAlert(dispatch,'error','Failed to update changes')
      }
        console.log('Data sent to localhost:8000', result);
        setLoading(false);
      } catch (fetchError) {
        setLoading(false);
        console.error('Error sending data to localhost:8000', fetchError);
        dispatchAlert(dispatch,'error','An error occurred. Please try again.')
      }
    };
  
    return (
      <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-2 sm:p-4 space-y-2 sm:space-y-4 max-w-screen-xl mx-auto">
        <NavButtons />
        <h1 className="text-xl mt-2 leading-none sm:text-3xl tracking-tight font-bold text-gray-100 dark:text-white text-center">
          Update your meal plan preferences!
        </h1>
    
        <div className="space-y-4 bg-slate-900 p-4 sm:p-8 rounded-lg shadow-lg w-full sm:w-96 mb-8">
          <div className="flex flex-col space-y-2">
            <label htmlFor="diet" className="text-lg font-medium text-gray-100">Diet type:</label>
            <select id="diet" value={diet} onChange={(e) => setDiet(e.target.value)} className="py-2 px-4 border rounded w-full" required>
              <option value="standard">Standard</option>
              <option value="vegetarian">Vegetarian</option>
              <option value="vegan">Vegan</option>
            </select>
          </div>
    
          <div className="flex flex-col space-y-2">
            <label htmlFor="foodType" className="text-lg font-medium text-gray-100">Preferred diet type:</label>
          <select id="foodType" value={foodType} onChange={(e) => setFoodType(e.target.value)} className="py-2 px-4 border rounded" required>
          <option value="American">American</option>
          <option value="Indian">Indian</option>
          <option value="Chinese">Chinese</option>
          <option value="African">African</option>
          <option value="European">European</option>
          <option value="Caribbean">Caribbean</option>
          <option value="Latin American">Latin American</option>
          <option value="Japanese">Japanese</option>
          <option value="Thai">Thai</option>
          <option value="Italian">Italian</option>
          <option value="Mexican">Mexican</option>
          <option value="Middle Eastern">Middle Eastern</option>
          <option value="Korean">Korean</option>
          <option value="Mediterranean">Mediterranean</option>
          <option value="French">French</option>
          <option value="Greek">Greek</option>
          <option value="Vietnamese">Vietnamese</option>
          <option value="Spanish">Spanish</option>
          <option value="Lebanese">Lebanese</option>
          <option value="Turkish">Turkish</option>
          <option value="Russian">Russian</option>
          <option value="Ethiopian">Ethiopian</option>
          </select>
          </div>

          <h6 className="text-xl leading-none sm:text-xl tracking-tight font-bold text-gray-100 dark:text-white text-center mt-4">
                    Please select all protein sources you prefer in your diet:
                </h6>
                <div class="flex flex-col justify-center items-center h-full">
                    <span class="text-gray-400 text-sm">(Select as many as you want but atleast 2)</span>
                </div>

                <div className="flex flex-wrap justify-center mt-2">
                    {proteinSources.map(protein => (
                        <button
                            key={protein}
                            className={`m-1 px-3 py-1.5 rounded-md text-white ${selectedProtein.includes(protein) ? 'bg-blue-500' : 'bg-gray-900'} hover:bg-slate-700`}
                            onClick={() => toggleProtein(protein)}>
                            {protein}
                        </button>
                    ))}
                </div>

          <div className="flex flex-col space-y-2">
            <label htmlFor="allergies" className="text-lg font-medium text-gray-100">Food you enjoy eating:</label>
            <div class="flex flex-col justify-center items-center h-full">
                    <span class="text-gray-400 text-sm">(Add as many as you want, will try to include them if it fits in your healthy meal plan)</span>
            </div>
            <textarea id="allergies" value={userFavFood} onChange={(e) => setUserFavFood(e.target.value)} placeholder="E.g. Chicken,potatoes,etc" className="py-2 px-4 border rounded w-full" rows="4"></textarea>
          </div>
    
          <div className="flex flex-col space-y-2">
            <label htmlFor="allergies" className="text-lg font-medium text-gray-100">Food you are allergic to:</label>
            <textarea id="allergies" value={user_allergies} onChange={(e) => setUserAllergies(e.target.value)} placeholder="E.g. peanuts, shellfish, soy, lactose" className="py-2 px-4 border rounded w-full" rows="4"></textarea>
          </div>
          
          <button
            className="py-2 px-4 bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 text-white rounded w-full mt-4 hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
            onClick={saveChanges}
            disabled={loading}>
            Save Changes
        </button>
        </div>
      </div>
    );
    
};

export default UpdateDietInfo;
