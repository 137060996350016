import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { useSelector, useDispatch } from 'react-redux';

const UserInfo = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();



    const goal = localStorage.getItem('user_fitness_goal');

    const [age, setAge] = useState('');
    const [gender, setGender] = useState('male');
    const [weight, setWeight] = useState('');
    const [unit, setUnit] = useState('metric');
    const [heightInFeet, setHeightInFeet] = useState('4');
    const [heightInInches, setHeightInInches] = useState('0');
    const [activity, setActivity] = useState('1.2');
    const [tdee, setTdee] = useState('');
    const [goalTDEE, setGoalTDEE] = useState('');
    const feetOptions = Array.from({ length: 5 }, (_, i) => i + 4);
    const inchesOptions = Array.from({ length: 12 }, (_, i) => i);

    const activityLevel = {
        "1.2": "Sedentary (office job)",
        "1.375": "Light Exercise (1-2 days/week)",
        "1.55": "Moderate Exercise (3-5 days/week)",
        "1.725": "Heavy Exercise (6-7 days/week)",
        "1.9": "Athlete (2x per day)"
    };

    const calculateTDEE = () => {
      const KG_TO_LBS = 2.20462;
      const CM_TO_INCHES = 0.393701;
  
      let adjustedWeight = unit === 'metric' ? weight : weight / KG_TO_LBS;
  
      let totalHeightInInches = (parseInt(heightInFeet) * 12) + parseInt(heightInInches);

      let adjustedHeightInCM = totalHeightInInches * 2.54;
  
      console.log(`Calculating TDEE: age=${age}, gender=${gender}, weight=${adjustedWeight}, height=${adjustedHeightInCM}, activity=${activity}`);
      console.log(`10 * ${parseFloat(adjustedWeight)} + 6.25 * ${parseFloat(adjustedHeightInCM)} - 5 * ${parseFloat(age)}`)
      
      let bmr = 10 * parseFloat(adjustedWeight) + 6.25 * parseFloat(adjustedHeightInCM) - 5 * parseFloat(age);

      if (gender === 'male') {
          bmr += 5;
      } else if (gender === 'female') {
          bmr -= 161;
      }
      console.log(`Calculated BMR: ${bmr}`)
      
      const calculatedTdee = bmr * parseFloat(activity);
      console.log(`Calculated TDEE: ${calculatedTdee}`);
  
      let goalTDEE = calculatedTdee;  // Default value
  
      if (goal === 'Lose Weight') {
          goalTDEE = calculatedTdee - 400; // Average between 400-500
      } else if (goal === 'Gain Muscle Mass') {
          goalTDEE = calculatedTdee + 400;
      } else{
            goalTDEE = calculatedTdee;
      }
  
      setTdee(calculatedTdee?.toFixed(2));
      if(goalTDEE) {
          setGoalTDEE(goalTDEE.toFixed(2));  // Update the state with goalTDEE
          localStorage.setItem('user_goal_tdee', goalTDEE.toFixed(2));  // Saving goalTDEE to local storage
      }
  
      // Save other user information to local storage
      localStorage.setItem('user_age', age);
      localStorage.setItem('user_gender', gender);
      localStorage.setItem('user_weight_in_kgs', adjustedWeight);
      localStorage.setItem('user_height_in_cm', adjustedHeightInCM);
      localStorage.setItem('user_activity', activity);
      localStorage.setItem('user_tdee', calculatedTdee.toFixed(2));
      navigate('/mealplanpreferences');
    };
  

    const handleFormSubmit = (event) => {
        event.preventDefault();
        calculateTDEE();
    };





  return (
            <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-4 space-y-4">
                <h1 className="text-xl mt-2 leading-none sm:text-2xl md:text-3xl lg:text-4xl tracking-tight font-bold text-gray-100 dark:text-white text-center">
                    Let's calculate your daily calorie needs
                </h1>
                <h2 className="mt-6 text-sm sm:text-base lg:text-lg text-slate-600 text-center max-w-3xl mx-auto dark:text-slate-400">
                    Your selected goal: 
                    <span className="text-blue-400 underline cursor-pointer" onClick={() => navigate('/')}>{goal}</span>
                </h2>  
                <form onSubmit={handleFormSubmit} className="space-y-4 bg-slate-900 p-4 sm:p-6 md:p-8 rounded-lg shadow-lg w-full sm:w-3/4 lg:w-1/2 xl:w-1/3 mb-8"> 
                    <div className="flex flex-col space-y-2">
                        <label htmlFor="age" className="text-lg font-medium text-gray-100">Age:</label>
                        <input type="number" id="age" placeholder="Age" value={age} onChange={(e) => setAge(e.target.value)} className="py-2 px-4 border rounded" required />
                    </div>
                    <div className="flex flex-col space-y-2">
                        <label htmlFor="gender" className="text-lg font-medium text-gray-100">Gender:</label>
                        <select id="gender" value={gender} onChange={(e) => setGender(e.target.value)} className="py-2 px-4 border rounded" required>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                    </div>
                    <div className="flex flex-col space-y-2">
                        <label htmlFor="unit" className="text-lg font-medium text-gray-100">Unit:</label>
                        <select id="unit" value={unit} onChange={(e) => setUnit(e.target.value)} className="py-2 px-4 border rounded" required>
                            <option value="metric">Metric (Kg's)</option>
                            <option value="imperial">Imperial (Pounds) </option>
                        </select>
                    </div>
                    <div className="flex flex-col space-y-2">
                        <label htmlFor="weight" className="text-lg font-medium text-gray-100">Weight {unit === 'metric' ? '(in kg)' : '(in lbs)'}:</label>
                        <input type="number" id="weight" placeholder={unit === 'metric' ? 'Weight in kg' : 'Weight in lbs'} value={weight} onChange={(e) => setWeight(e.target.value)} className="py-2 px-4 border rounded" required />
                    </div>
                    <div className="flex flex-col space-y-2">
                    <label className="text-lg font-medium text-gray-100">Height:</label>
                    <div className="flex space-x-2">
                        <select id="heightFeet" value={heightInFeet} onChange={(e) => setHeightInFeet(e.target.value)} className="py-2 px-4 border rounded w-1/2" required>
                            {feetOptions.map(foot => (
                                <option key={foot} value={foot}>{foot} ft</option>
                            ))}
                        </select>

                        <select id="heightInches" value={heightInInches} onChange={(e) => setHeightInInches(e.target.value)} className="py-2 px-4 border rounded w-1/2" required>
                            {inchesOptions.map(inch => (
                                <option key={inch} value={inch}>{inch} in</option>
                            ))}
                        </select>
                    </div>
                    </div>
                    <div className="flex flex-col space-y-2">
                    <label htmlFor="activity" className="text-lg font-medium text-gray-100">Activity level (include workout days selected) :</label>
                    <select id="activity" value={activity} onChange={(e) => setActivity(e.target.value)} className="py-2 px-4 border rounded w-full" required>
                        {Object.keys(activityLevel).map(level => (
                            <option key={level} value={level}>{activityLevel[level]}</option>
                        ))}
                    </select>
                    </div>
                    <button type="submit" className="py-2 px-4 bg-slate-700 text-white rounded w-full hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">Submit</button>
                </form>
            </div>
);
};

export default UserInfo;
