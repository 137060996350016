import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Navigation/Footer';

const PrivacyPolicy = () => {
    return (
        <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-4 space-y-4">
            {/* Header */}
            <header className="w-full text-center mb-12">
                <h1 className="text-[2.5rem] mt-2 leading-none sm:text-3xl tracking-tight font-bold text-gray-100 dark:text-white">
                    Privacy Policy
                </h1>
            </header>

            {/* Content */}
            <div className="space-y-4 bg-slate-900 p-8 rounded-lg shadow-lg max-w-5xl w-full mx-auto mb-8 text-gray-300">
                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Introduction</h2>
                    <p>At GetFitterAI, we value your privacy and strive to protect your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data.</p>
                </section>

                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Information We Collect</h2>
                    <p>We collect various types of information to better tailor our services to your needs:</p>
                    <ul className="list-disc pl-5">
                        <li>Name</li>
                        <li>Email Address</li>
                        <li>Age</li>
                        <li>Weight</li>
                        <li>Height</li>
                        <li>Fitness Goals</li>
                        <li>Dietary Preferences</li>
                    </ul>
                </section>

                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">How We Use Your Information</h2>
                    <p>We use the information collected to provide personalized plans and improve our services. Additionally, we may use your email address for marketing purposes, including sending newsletters, offers, and promotions with your consent.</p>
                </section>

                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Third-Party Sharing</h2>
                    <p>We may share your data with trusted third-party providers to enhance our services. We ensure all third parties comply with strict data protection standards.</p>
                </section>

                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">How We Protect Your Information</h2>
                    <p>We implement robust security measures to protect your data. Our security protocols include encryption, access controls, and other measures to ensure data confidentiality and integrity.</p>
                </section>

                <section className="mb-10">
                    <h2 className="text-xl font-bold mb-4">Contact Us</h2>
                    <p>If you have any questions about this Privacy Policy, feel free to contact us at:</p>
                    <p>Email: support@getfitter.ai</p>
                </section>
            </div>

            <Footer />
        </div>
    );
};

export default PrivacyPolicy;
