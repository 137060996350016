import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { useSelector, useDispatch } from 'react-redux';
import dispatchAlert from '../Utils/Functions/DispatchAlert';
import NavButtons from '../Utils/Components/NavButtons'
import {getUserData} from '../Auth/CompleteAuth'


const UpdateOthers = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  // Combine initial local storage reads
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState();
  const [paymentMethod, setPaymentMethod] = useState();

  const [email,setEmail] = useState(localStorage.getItem('user_email') || '');
  const [fullName,setFullName] = useState(localStorage.getItem('user_full_name') || '');
  const [userID,setUserID] = useState(localStorage.getItem('user_id') || '');

  var url = `https://getfitter.lemonsqueezy.com/checkout/buy/cab108d7-fd40-48e4-969d-a8feaeaf70a7?checkout[custom][user_id]=${userID}&checkout[email]=${email}&checkout[name]=${fullName}`
  
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleContinuePro = () => {
    window.open(url, '_blank'); // '_blank' opens the URL in a new tab. If you want to open it in the same tab, just remove '_blank'.
  };

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const { data: { session } } = await supabase.auth.getSession();
      const fetchedData = await getSubscriptionData(session['access_token']);
      setSubscriptionInfo(fetchedData);
      console.log('fetchedData:', fetchedData);
      localStorage.setItem('subscription_data', JSON.stringify(fetchedData));
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };

  const getSubscriptionData = async (sessionObj) => {
    console.log('getSubscriptionData called');
    try {
      const response = await fetch('https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/get_user_data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionObj}`
        },
        body: JSON.stringify({ table: 'user_subscriptions' })
      });

      if (!response.ok) throw new Error('Network response was not ok');
      return await response.json();

    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  const restorePurchase = async () => { 
    const fetchData = async () => {
      const lastExecutionTimestamp = localStorage.getItem('lastExecutionTimestamp');
  

      const { data: { session }, } = await supabase.auth.getSession();
      if (session) {
        const accessToken = session.access_token;
        const route = await getUserData(accessToken);
        if (route) {
          console.log("user data is updated");
        }
        else {
          console.log("user data is not updated");
        }
        localStorage.setItem('lastExecutionTimestamp', Date.now().toString());
      }
    };
  
    fetchData();

  }


  const cancelSubscription = async () => {
    // Confirm the user's intention to cancel the subscription
    if (window.confirm('Are you sure you want to cancel the subscription?')) {
      try {
        const { data: { session }, } = await supabase.auth.getSession();
        
        const response = await fetch('https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/cancel_subscription', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session['access_token']}`
          },
          body: JSON.stringify({
            subscription_id: subscriptionInfo.subscription_id,
          }),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok'); // You can throw the specific error message if needed
        }
  
        const responseData = await response.json();
  
        // If the backend provides specific info about the updated row, you can use it here
        if (responseData.updatedRow) {
          setSubscriptionInfo(responseData.updatedRow);
          localStorage.setItem('subscription_data', JSON.stringify(responseData.updatedRow));
        }
        
        dispatchAlert(dispatch, 'success', 'Subscription canceled successfully');
      } catch (error) {
        dispatchAlert(dispatch, 'error', 'Failed to cancel subscription');
        console.error('Error:', error);
      }
    }
  };
  
  
  


  return (
    <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-4 space-y-4 sm:space-y-8">
      <NavButtons />
  
      <div className="space-y-4 bg-slate-900 p-4 sm:p-8 rounded-lg shadow-lg w-full max-w-xl">
        {loading ? (
            <div className="flex items-center space-x-2">
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <span className="text-white">Getting info, pls wait..</span>
            </div>
        ) : (
          <>
            {subscriptionInfo && (subscriptionInfo.status_name === "active" || subscriptionInfo.status_name === "on_trial") && subscriptionInfo.manually_cancelled !== 'true' ? (
              <div className="text-center p-4">
                <p className="text-white text-sm sm:text-base">Congrats on being a Pro user! Your current subscription will be renewed at : {new Date(subscriptionInfo.renew_date).toDateString()}.</p>
                <button 
                  className="mt-4 bg-red-500 text-white text-sm sm:text-base p-2 rounded hover:bg-red-600"
                  onClick={cancelSubscription}
                >
                  Cancel Subscription
                </button>
              </div>
            ) : subscriptionInfo && subscriptionInfo.manually_cancelled === 'true' ? (
              <div className="text-center p-4">
                <p className="text-white text-sm sm:text-base">Thanks for being a pro user, your subscription will end at {new Date(subscriptionInfo.renew_date).toDateString()}.</p>
                <button 
                className="mt-4 bg-blue-500 text-white text-sm sm:text-base p-2 rounded hover:bg-blue-600"
                onClick={handleContinuePro}
              >
                Continue being a Pro
              </button>
              </div>
            ) : (
              <div className="text-center p-4">
                <p className="text-white text-sm sm:text-base">Become a pro and enjoy all the below benefits</p>
                <ul className="text-white text-sm sm:text-base list-disc list-inside">
                  <li>Three Different Meal types</li>
                  <li>Unlimited number of meal resets</li>
                  <li>Unlimited Replace Meals</li>
                  <li>Unlimited workout resets</li>
                  <li>Unlimited Replace Workouts</li>
                  <li>Priority support</li>
                </ul>
                <button 
                className="mt-4 bg-blue-500 text-white text-sm sm:text-base p-2 rounded hover:bg-blue-600"
                onClick={handleContinuePro}
              >
                Get Pro
              </button>
              <p className="text-white text-sm sm:text-base">If you have already purchased pro subscription but are not seeing it use the button below to restore your purchase</p>
              <button 
                className="mt-4 bg-blue-500 text-white text-sm sm:text-base p-2 rounded hover:bg-blue-600"
                onClick={restorePurchase}
              >
                Restore Purchase
              </button>
              </div>
              
            )}
          </>
        )}
      </div>
    </div>
  );
  
  
};

export default UpdateOthers;
