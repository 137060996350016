import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import {currentLogo, currentBrandingColors,currentSubdomain} from '../whitelabel/constants';



function Header() {





    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [logoUrl, setLogoUrl] = useState(currentLogo); // Default logo
    const [siteTitle, setSiteTitle] = useState(currentSubdomain=='www'?"GetFitterAI":currentSubdomain); // Default title


    useEffect(() => {
        const updateAuthState = (event, session) => {
            setIsAuthenticated(!!session);
        };
    
        // Check the initial authentication status
        checkAuthentication();
    
        // Register the event listener
        supabase.auth.onAuthStateChange(updateAuthState);

        const hostname = window.location.hostname;
        const subdomain = hostname.split('.')[0]; // Assumes format subdomain.domain.tld
        setLogoUrl(currentLogo); // Fallback to 'www' if no match
        setSiteTitle(currentSubdomain=='www'?"GetFitterAI":currentSubdomain); // Set site title to subdomain

    
        return () => {
            // This is the recommended cleanup but if Supabase provides a specific function, use that
            supabase.auth.onAuthStateChange(updateAuthState, null);
        };
    }, []);
    
    

    const checkAuthentication = async () => {
        const { data, error } = await supabase.auth.getSession();
        if (data && data.session) {
            setIsAuthenticated(true);
            console.log('User is authenticated:', data);
        } else {
            setIsAuthenticated(false);
            if (error) {
                console.error('Error checking authentication:', error.message);
            } else {
                console.log('User is not authenticated.');
            }
        }
    };
    
    const signOut = async () => {
        const { error } = await supabase.auth.signOut();
        if (error) {
            console.log('Error logging out:', error.message);
        } else {
            console.log('User logged out successfully');
            navigate('/signin');
        }
    };

    // If user is authenticated, do not display the header.
    if (isAuthenticated) return null;

    return (
        <header className="bg-slate-800 text-sky-500 p-4 shadow-md">
            <div className="container mx-auto flex flex-row justify-between items-center px-4 md:px-0">
                <button onClick={() => navigate('/')} className="flex items-center space-x-3 mb-4 md:mb-0">
                    <img src={logoUrl} alt="Logo" className="h-9 w-9" />
                    <h1 className="leading-none sm:text-2xl tracking-tight font-bold text-white">
                        {siteTitle=='www' ? 'GETFITTER.AI' : siteTitle}
                    </h1>
                </button>
    
                <nav className="flex space-x-4">
                    {!isAuthenticated ? (
                        <>
                            <button onClick={() => window.location.href = 'https://blog.getfitter.ai'} className="text-white font-bold">
                            Blog
                            </button>
                            <button onClick={() => navigate('/signin')} className="text-white font-bold">
                                Sign In
                            </button>
                            <button onClick={() => navigate('/signup')} className="text-white font-bold">
                                Sign Up
                            </button>
                        </>
                    ) : (
                        <>
                            <button 
                                className="px-2 py-1 rounded hover:bg-white hover:text-blue-500 transition ease-in-out duration-200"
                                onClick={() => navigate('/diet')}>
                                My Diet
                            </button>
                            <button 
                                className="px-2 py-1 rounded hover:bg-white hover:text-blue-500 transition ease-in-out duration-200"
                                onClick={() => navigate('/workouts')}>
                                My Workouts
                            </button>
                            <button 
                                className="px-2 py-1 bg-red-500 rounded hover:bg-red-600 transition ease-in-out duration-200"
                                onClick={() => signOut()}>
                                Signout
                            </button>
                        </>
                    )}
                </nav>

            </div>
        </header>
    );
    
}

export default Header;