// store.js
import { configureStore } from '@reduxjs/toolkit';
import alertReducer from './features/alertSlice';
import dietReducer from './features/dietIteamModifyingSlice';
import workoutitemReducer from './features/dietIteamModifyingSlice';


const store = configureStore({
  reducer: {
    alert: alertReducer,
    diet: dietReducer,
    workout: workoutitemReducer
  }
});

export default store;
