import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dietItemsModifying: [],
};

const dietSlice = createSlice({
  name: 'diet',
  initialState,
  reducers: {
    setDietItemsModifying: (state, action) => {
      state.dietItemsModifying = action.payload;
    },
    addDietItem: (state, action) => {
      state.dietItemsModifying.push(action.payload);
    },
    removeDietItem: (state, action) => {
      state.dietItemsModifying = state.dietItemsModifying.filter(item => item !== action.payload);
    },
  },
});

export const { setDietItemsModifying, addDietItem, removeDietItem } = dietSlice.actions;

export default dietSlice.reducer;
