import React, { useEffect, useState,useMemo, } from 'react';
import { useParams } from 'react-router-dom';
import breakfastImage from '../../../assets/meals/breakfast.jpeg';
import lunchImage from '../../../assets/meals/lunch.jpeg';
import dinnerImage from '../../../assets/meals/dinner.jpeg';
import snacksImage from '../../../assets/meals/snacks.jpeg';
import { useNavigate } from 'react-router-dom';
import ProUpgradeOverlay from '../../Utils/Components/ProOverlay'; // Adjust the path accordingly
import dispatchAlert from '../../Utils/Functions/DispatchAlert'
import writeUserData from '../../Utils/Functions/WriteUserData'
import ReactGA from 'react-ga';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io"

import { useSelector, useDispatch } from 'react-redux';


const SavedDiets = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [userData, setUserData] = useState({});
  const [mealData, setMealData] = useState([]);
  const [itemsBeingReplaced, setItemsBeingReplaced] = useState([]);
  const [expandedMeal, setExpandedMeal] = useState("");
  const [isLoading, setIsLoading] = useState();
  const [currentDay, setCurrentDay] = useState(); // New state to track the current day
  const [showProOverlay, setShowProOverlay] = useState();

  const [dietId, setDietId] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [publicValue, setPublicValue] = useState(false);
  const [userActivity, setUserActivity] = useState(null);
  const [userAge, setUserAge] = useState('');
  const [userAllergies, setUserAllergies] = useState('');
  const [userDietPreference, setUserDietPreference] = useState('');
  const [userFavFood, setUserFavFood] = useState('');
  const [userFitnessGoal, setUserFitnessGoal] = useState('');
  const [userFoodPreference, setUserFoodPreference] = useState('');
  const [userGender, setUserGender] = useState('');
  const [userGoalTdee, setUserGoalTdee] = useState('');
  const [userHeightInCm, setUserHeightInCm] = useState('');
  const [userId, setUserId] = useState('');
  const [userLikedFood, setUserLikedFood] = useState(null);
  const [userPreferredProteinSources, setUserPreferredProteinSources] = useState('');
  const [userTdee, setUserTdee] = useState('');
  const [userWeightInKgs, setUserWeightInKgs] = useState('');
  const [showFullText, setShowFullText] = useState(false);





  const { id } = useParams();
  console.log("The diet id is : ",id)

  



  useEffect(() => {
      // Check if mealData exists in local storage
      console.log('useEffect called')
      console.log(`userMealDataDay${currentDay}`) 
      console.log(fetchData(currentDay));
        
      


  }, []);


  const fetchData = async (selectedDay) => {
    const sessionObj = JSON.parse(localStorage.getItem('sb-ygcptpjdpipejtvnjnlg-auth-token'));
    setIsLoading(true);

    try {
      const response = await fetch('https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/get_saved_data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ table: 'saved_diets',diet_id:id })
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        console.log('the meal data is :');
        const meal_data = data.diet_one;
        console.log(meal_data);
        setMealData(meal_data);
        
        setDietId(data.diet_id);
        setCreatedAt(data.created_at);
        setPublicValue(data.public);
        setUserActivity(data.user_activity);
        setUserAge(data.user_age);
        setUserAllergies(data.user_allergies);
        setUserDietPreference(data.user_diet_preference);
        setUserFavFood(data.user_fav_food);
        setUserFitnessGoal(data.user_fitness_goal);
        setUserFoodPreference(data.user_food_preference);
        setUserGender(data.user_gender);
        setUserGoalTdee(data.user_goal_tdee);
        setUserHeightInCm(data.user_height_in_cm);
        setUserId(data.user_id);
        setUserLikedFood(data.user_liked_food);
        setUserPreferredProteinSources(data.user_preferred_protein_sources);
        setUserTdee(data.user_tdee);
        setUserWeightInKgs(data.user_weight_in_kgs);
         

        localStorage.setItem(`userMealDataDay${selectedDay}`, JSON.stringify(meal_data));
        setIsLoading(false);
    })
      .catch((error) => {
          console.error('Error:', error);
          setIsLoading(false);
      });


    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
}




const groupedMeals = ["Breakfast", "Lunch", "Snacks", "Dinner"].map(mealType => {
  return {
      type: mealType,
      items: mealData.filter(item => {
          if (mealType === "Snacks") {
              return item.Meal === "Snack" || item.Meal === "Snacks";
          } else {
              return item.Meal === mealType;
          }
      })
  };
});




  const total = mealData.reduce((acc, meal) => {
    acc.weight += parseFloat(meal['Weight (g)']);
    acc.calories += Number.isInteger(parseFloat(meal['Calories'])) ? parseFloat(meal['Calories']) : 0;
    acc.protein += parseFloat(meal['Protein (g)']);
    acc.carbs += parseFloat(meal['Carbs (g)']);
    acc.fat += parseFloat(meal['Fat (g)']);
     
    return acc;
  }, { weight: 0, calories: 0, protein: 0, carbs: 0, fat: 0 });


  const toggleMealExpansion = (mealType) => {
    if (expandedMeal === mealType) {
      setExpandedMeal(''); // Close if it's currently open
    } else {
      setExpandedMeal(mealType); // Open the clicked one
    }
  };


  const handleIntakeToggle = (item) => {
      // Handle the logic for toggling the intake tracking for the given item.
  };
  



  const switchDay = (day) => { 
    var extractedDay = day.charAt(day.length-1);
    console.log(extractedDay)
    // Check the user_pro value from local storage and convert it to an integer
   



    setCurrentDay(extractedDay);
};

const handleSignup = () => {
  navigate('/signup');
};

const ProIntercept = () => {
  // Check if 'user_pro' exists in localStorage
  if (!localStorage.getItem('user_pro')) {
    localStorage.setItem('user_pro', '0');  // Set it to '0' if it doesn't exist
  }

  const userProValue = parseInt(localStorage.getItem('user_pro'), 10);
  return userProValue !== 0;
};

const checkAuth = () => {
  return !!localStorage.getItem('sb-ygcptpjdpipejtvnjnlg-auth-token');
};

const SignupAlert = () => {
  const authDetails = checkAuth();
  if (!authDetails) {
    const isPro = ProIntercept();
    if (!isPro) {
      dispatchAlert(dispatch, "alert", (
        <>
          To generate a customized diet plan, workout plan, 24/7 AI personal trainer all tailored to you,{' '}
          <span className="text-blue-500 underline" onClick={handleSignup}>
            Sign up
          </span>{' '}
          for a 14-day free trial of GetFitter.AI.
        </>
      ));
    }
  }
};












  return (
    <div className="diet-content-wrapper relative">

      <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-4 space-y-6">
          <h1 className="text-3xl mt-4 font-bold text-white text-center">
              GetFitterAI's Diet Plan
          </h1>
  
          {userTdee && userGoalTdee && userFitnessGoal && userWeightInKgs && userHeightInCm && userAge && userGender && userDietPreference && userFoodPreference && (
        <div className="text-base text-gray-400 text-center mb-4 w-full sm:w-1/2 lg:w-1/3 xl:w-1/3">
          <p>
            {showFullText ? (
              <>
                This is the diet plan of a user whose daily calorie needs are <span className="font-bold text-white">{userTdee}</span>, and their target calories are <span className="font-bold text-white">{userGoalTdee}</span> based on their fitness goal of <span className="font-bold text-white">{userFitnessGoal}</span>. {userGender === 'male' ? 'He' : 'She'} weighs <span className="font-bold text-white">{userWeightInKgs} kg</span> with a height of <span className="font-bold text-white">{userHeightInCm} cm</span>. {userGender === 'male' ? 'He' : 'She'} is <span className="font-bold text-white">{userAge} years</span> old and identifies as <span className="font-bold text-white">{userGender === 'male' ? 'male' : 'female'}</span>. {userGender === 'male' ? 'His' : 'Her'} diet preference is <span className="font-bold text-white">{userDietPreference}</span>, and {userGender === 'male' ? 'his' : 'her'} food preference leans towards <span className="font-bold text-white">{userFoodPreference}</span>.
                <button onClick={() => setShowFullText(false)} className="text-white-500 underline"><IoIosArrowDropupCircle className="inline-block align-text-bottom" size={24}/></button>
              </>
            ) : (
              <>
                This is the diet plan of a user whose daily calorie needs are <span className="font-bold text-white">{userTdee}</span>, and their target calories are <span className="font-bold text-white">{userGoalTdee}</span>. <button onClick={() => setShowFullText(true)} className="text-white-500 underline"><IoIosArrowDropdownCircle className="inline-block align-text-bottom "size={24} /></button>
              </>
            )}
          </p>



        </div>
      )}
          <div className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/3 mx-auto space-y-6 bg-slate-900 p-6 shadow-xl rounded-lg">

            {isLoading ? (
                <div className="flex items-center space-x-2">
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <span className="text-white">Getting your custom healthy meal plan, please wait it may take few seconds..</span>
                </div>
            ) : (
                <>
                    {groupedMeals.map(group => {
              const mealTotals = group.items.reduce((acc, currItem) => {
                acc.calories += Number.isInteger(parseFloat(currItem["Calories"])) ? parseFloat(currItem["Calories"]) : 0;
                acc.protein += parseFloat(currItem["Protein (g)"]);
                acc.carbs += parseFloat(currItem["Carbs (g)"]);
                acc.fat += parseFloat(currItem["Fat (g)"]);
                return acc;
              }, { calories: 0, protein: 0, carbs: 0, fat: 0 });
  
                return (
                  <div key={group.type} className="bg-slate-900 shadow mb-4 rounded-lg">
                    <button 
                      onClick={() => toggleMealExpansion(group.type)}
                      className={`text-xl font-semibold py-2 px-5 w-full text-left text-black focus:outline-none
                                  ${expandedMeal === group.type ? "" : "rounded-b-lg"}
                                  ${group.type === "Breakfast" ? "bg-yellow-300" : 
                                  group.type === "Lunch" ? "bg-green-300" :
                                  group.type === "Dinner" ? "bg-blue-300" :
                                  "bg-purple-300"} rounded-t-lg`}
                    >
                      <div className="flex justify-between text-black">
                        <span>{group.type}</span>
                        <span>{mealTotals.calories.toFixed(2)} Calories</span>
                      </div>
                      <div className="text-xs font-normal mt-1 text-black">
                        {mealTotals.protein.toFixed(2)}g protein, {mealTotals.carbs.toFixed(2)}g carbs, {mealTotals.fat.toFixed(2)}g fat
                      </div>
        
                      <div className="text-black">
                        {expandedMeal === group.type ? "▲" : "▼"}
                      </div>
                    </button>
        
                    {expandedMeal === group.type && (
                      <table className="w-full border-collapse py-2 px-5">
                        <tbody>
                          {group.items.map((item, index) => (
                            <tr key={index} className="py-1">
                              <td className="border p-2">
                                <img 
                                  src={
                                    group.type === "Breakfast" ? breakfastImage :
                                    group.type === "Lunch" ? lunchImage :
                                    group.type === "Dinner" ? dinnerImage :
                                    group.type === "Snacks" ? snacksImage : null
                                  } 
                                  alt={item.Item} 
                                  width="40"
                                  height="40" 
                                  className="rounded"
                                />
                              </td>
                              <td className="border p-2 text-white">
                                <div className="font-bold">{item["Item"]}</div>
                                <div>{item["Weight (g)"]}g, {item["Calories"]} cal</div>
                                <div>{item["Protein (g)"]}g protein, {item["Carbs (g)"]}g carbs, {item["Fat (g)"]}g fat</div>
                              </td>
                              <td className="border p-2 text-center h-10">
                                  {
                                      itemsBeingReplaced.includes(item.id) 
                                      ? (
                                          <div className="flex items-center justify-center h-full">
                                              <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                              </svg>
                                          </div>
                                      ) 
                                      : (
                                          <div className="flex items-center justify-center h-full">
                                              <button onClick={() => SignupAlert()} className="py-1 px-2 bg-slate-700 text-white rounded hover:bg-slate-600 focus:outline-none">Replace</button>
                                          </div>
                                      )
                                  }
                              </td>
                              <td className="border p-2 text-white">
                                <input type="checkbox" onChange={() => handleIntakeToggle(item)}/>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                );
              })}

                    {/* Total card */}
                    <div className="bg-red-300 shadow p-2 rounded-lg w-full mb-4">
                        <div className="text-xl font-semibold flex justify-between px-5 pr-3">
                            <span>Total</span>
                            <span>{total.calories.toFixed(2)} Calories</span>
                        </div>
                        <div className="text-xs font-normal px-5">
                            {total.protein.toFixed(2)}g protein, {total.carbs.toFixed(2)}g carbs, {total.fat.toFixed(2)}g fat
                        </div>
                    </div>

                    {!checkAuth() && (
                      <div className="w-full flex justify-center mt-6">
                        <button 
                            onClick={() => SignupAlert()} 
                            className="bg-gradient-to-r from-pink-500 to-purple-600 text-white py-2 px-6 rounded-full hover:bg-purple-700 focus:outline-none shadow-md"
                        >
                            {"Get Your Own Meal Plan"}
                        </button>
                      </div>
                    )}

                </>
          )}

        </div>
        {showProOverlay && (
          <ProUpgradeOverlay 
            isVisible={showProOverlay} 
            onClose={() => setShowProOverlay(false)} 
            onUpgrade={() => {
              // Handle the logic for upgrading to Pro here
              setShowProOverlay(false);
            }}
          />
        )}
        </div>
    </div>
);


}

export default SavedDiets;