import React from 'react';

const logos = {
  'circute31fitness': 'https://github.com/prudhvireddym/getfitter-logos/blob/main/circute31fitness.png?raw=true',
  'blog': 'https://github.com/prudhvireddym/getfitter-logos/blob/main/getfittertlogo512.png?raw=true',
  'www': 'https://github.com/prudhvireddym/getfitter-logos/blob/main/getfittertlogo512.png?raw=true',
};

const brandingColors = {
  'circute31fitness': {
    primary: '#0AE2D5',
    secondary: '#5387A0',
    headerColor:"#FFFFFF",
    text: '#FFFFFF',
  },
  'blog': {
    primary: '#000000',
    secondary: '#FFFFFF',
    text: '#FFFFFF',
  },
  'www': {
    primary: '#FF0000',
    secondary: '#FFFFFF',
    text: '#FFFFFF',
  },
};

function getSubdomain() {
  const { hostname } = window.location;
  const parts = hostname.split('.');
  if (parts.length < 2) {
    return 'www'; // Default subdomain
  }
  return parts[0];
}

const subdomain = getSubdomain();

export const currentLogo = logos[subdomain] || logos['www'];
export const currentBrandingColors = brandingColors[subdomain] || brandingColors['www'];
export const currentSubdomain = subdomain;
