import React, { useEffect, useState } from 'react';

const SavedPlans = () => {
    const [selectedCategory, setSelectedCategory] = useState("diets");
    const [savedPlans, setSavedPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null); // State for error handling

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const getUserSavedData = async (sessionObj, saved_type) => {
        try {
            console.log("Fetching user saved data...");
            setLoading(true);
            setError(null); // Reset error state before fetching
            const get_data = saved_type === "diets" ? "saved_diets" : "saved_workouts";

            const response = await fetch('https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/get_saved_list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionObj['access_token']}`
                },
                body: JSON.stringify({ table: get_data })
            });

            if (!response.ok) {
                if (response.status === 404) {
                    throw new Error('No saved plans found.');
                } else {
                    throw new Error(`Error ${response.status}: ${response.statusText}`);
                }
            }

            const data = await response.json();
            console.log('Successfully retrieved user saved data:', data);
            
            // Ensure data is an array
            if (Array.isArray(data)) {
                setSavedPlans(data);
            } else {
                console.warn('Expected data to be an array, but received:', data);
                setSavedPlans([]);
                setError('Received unexpected data format from server.');
            }
        } catch (error) {
            console.error('Error fetching saved data:', error);
            setSavedPlans([]); // Reset savedPlans on error
            if (error.message === 'No saved plans found.') {
                setError('No saved plans found.');
            } else {
                setError('Failed to fetch saved plans. Please try again later.');
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Fetch saved diets and workouts from the database
        const sessionString = localStorage.getItem('sb-ygcptpjdpipejtvnjnlg-auth-token');
        if (sessionString) {
            try {
                const sessionObj = JSON.parse(sessionString);
                getUserSavedData(sessionObj, selectedCategory); // Fetch data based on the selected category
            } catch (parseError) {
                console.error('Error parsing session data:', parseError);
                setLoading(false);
                setError('Invalid session data. Please log in again.');
            }
        } else {
            setLoading(false); // No session found, stop loading
            setError('No active session found. Please log in.');
        }
    }, [selectedCategory]); // Re-fetch data when selectedCategory changes

    // Helper function to group workouts by day
    const groupWorkoutsByDay = (workouts) => {
        if (!Array.isArray(workouts)) {
            console.warn("workouts is not an array:", workouts);
            return {};
        }
        return workouts.reduce((acc, workout) => {
            const day = workout.Day;
            if (!day) {
                console.warn("Workout item missing 'Day' property:", workout);
                return acc;
            }
            if (!acc[day]) {
                acc[day] = [];
            }
            acc[day].push(workout);
            return acc;
        }, {});
    };

    return (
        <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-4 space-y-4">
            <h1 className="text-3xl mt-2 leading-none sm:text-4xl tracking-tight font-bold text-gray-100 dark:text-white text-center">
                Saved Plans
            </h1>
            <hr className="w-48 border-t-2 border-gray-300 mt-2 mb-4" />

            <div className="bg-slate-900 p-4 rounded-lg shadow-lg w-96 mb-4">
                <label htmlFor="category" className="text-lg font-medium text-gray-100">Select Category:</label>
                <select
                    id="category"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    className="mt-2 p-2 w-full border rounded bg-gray-800 text-gray-100"
                >
                    <option value="diets">Diets</option>
                    <option value="workouts">Workouts</option>
                </select>
            </div>

            {error && (
                <div className="bg-red-600 text-white p-4 rounded-lg shadow-lg w-96 text-center">
                    {error}
                </div>
            )}

            <div className="space-y-4 w-full max-w-4xl">
                {loading ? (
                    <div className="flex items-center space-x-2 justify-center">
                        <svg className="animate-spin h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <span className="text-white">Fetching your saved plans, please wait a moment...</span>
                    </div>
                ) : error ? (
                    // Display error message
                    <div className="bg-red-600 text-white p-6 rounded-lg shadow-lg w-full max-w-2xl mx-auto flex flex-col items-center">
                        <svg className="h-12 w-12 mb-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01M21 12c0-4.97-4.03-9-9-9S3 7.03 3 12s4.03 9 9 9 9-4.03 9-9z" />
                        </svg>
                        <p className="text-lg">{error}</p>
                    </div>
                ) : savedPlans && savedPlans.length > 0 ? (
                    savedPlans.map((plan, index) => (
                        <div key={plan.id || index} className="bg-slate-900 p-6 rounded-lg shadow-lg w-full mx-auto">
                            <h2 className="text-2xl font-semibold text-gray-100 text-center mb-4">
                                {selectedCategory === "diets" ? "Saved Diet Plan" : "Saved Workout Plan"}
                            </h2>

                            {/* Show Full Plan Button */}
                            {selectedCategory === "diets" && plan.diet_id && (
                                <button
                                    onClick={() => window.location.href = `https://www.getfitter.ai/fulldiet/${plan.diet_id}`}
                                    className="mb-4 p-2 w-full bg-blue-600 text-gray-100 rounded hover:bg-blue-700 transition-colors"
                                >
                                    Show Full Diet Plan
                                </button>
                            )}
                            {selectedCategory === "workouts" && plan.workout_id && (
                                <button
                                    onClick={() => window.location.href = `https://www.getfitter.ai/fullworkout/${plan.workout_id}`}
                                    className="mb-4 p-2 w-full bg-blue-600 text-gray-100 rounded hover:bg-blue-700 transition-colors"
                                >
                                    Show Full Workout Plan
                                </button>
                            )}

                            {/* List of Diet Items or Workout Items */}
                            {selectedCategory === "diets" ? (
                                <ul className="list-disc pl-5 space-y-2 text-center">
                                    {plan.diet_one && Array.isArray(plan.diet_one) && plan.diet_one.length > 0 ? (
                                        plan.diet_one.map((item) => (
                                            <li key={item.id} className="text-gray-100">
                                                <span className="font-medium">{item.Meal}:</span> {item.Item} - {item.Calories} Calories
                                            </li>
                                        ))
                                    ) : (
                                        <li className="text-gray-100">No diet items available.</li>
                                    )}
                                </ul>
                            ) : (
                                <ul className="space-y-2">
                                    {plan.workout_plan && Array.isArray(plan.workout_plan) && plan.workout_plan.length > 0 ? (
                                        Object.entries(groupWorkoutsByDay(plan.workout_plan)).map(([day, workouts]) => (
                                            <li key={day} className="bg-slate-800 p-4 rounded-lg shadow-md">
                                                <h3 className="text-xl font-semibold text-gray-100 mb-2 text-center">{day}</h3>
                                                <ul className="list-disc pl-5 space-y-1">
                                                    {workouts.map((workout) => (
                                                        <li key={workout.id} className="text-gray-100">
                                                            <span className="font-medium">{workout["Workout Name"]}:</span> {workout["Body Part Targeted"]}
                                                            {workout["No of Sets"] && workout["No of Reps"] ? ` - ${workout["No of Sets"]} Sets x ${workout["No of Reps"]} Reps` : ''}
                                                            {workout["Youtube Video Link"] && (
                                                                <a href={workout["Youtube Video Link"]} target="_blank" rel="noopener noreferrer" className="text-blue-400 ml-2">
                                                                    [Video]
                                                                </a>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        ))
                                    ) : (
                                        <li className="text-gray-100">No workout items available.</li>
                                    )}
                                </ul>
                            )}
                        </div>
                    ))
                ) : (
                    // Display "No saved plans found" with enhanced UI
                    <div className="bg-slate-800 p-6 rounded-lg shadow-lg w-full max-w-2xl mx-auto flex flex-col items-center">
                        <svg className="h-12 w-12 mb-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 13h6m2 0a2 2 0 012 2v5a2 2 0 01-2 2H7a2 2 0 01-2-2v-5a2 2 0 012-2m10 0V7a2 2 0 00-2-2H9a2 2 0 00-2 2v6" />
                        </svg>
                        <p className="text-lg text-gray-300">No saved plans found. Start by saving your favorite diets or workouts!</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default SavedPlans;
