import React, { useEffect, useState } from 'react';
import youtube from '../../../assets/main/youtube.png';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import dispatchAlert from '../../Utils/Functions/DispatchAlert'
import ProUpgradeOverlay from '../../Utils/Components/ProOverlay'; // Adjust the path accordingly
import { useParams } from 'react-router-dom';
import { set } from 'react-ga';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from 'react-icons/io';


const SavedWorkouts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});

  const [workoutReplaced, setWorkoutReplaced] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [workoutsBeingReplaced, setWorkoutsBeingReplaced] = useState([]);
  const [showProOverlay, setShowProOverlay] = useState(false);

  const [userExperienceLevel, setUserExperienceLevel] = useState('');
  const [userFitnessGoal, setUserFitnessGoal] = useState(false)
  const [userPreferredWorkoutDays, setUserPreferredWorkoutDays] = useState(false);
  const [userWorkoutType, setUserWorkoutType] = useState([]);
  const [workoutData, setWorkoutData] = useState([]);
  const [selectedDay, setSelectedDay] = useState('Day 1');
  const [showFullDetails, setShowFullDetails] = useState(false);





  const [email,setEmail] = useState(localStorage.getItem('user_email') || '');
  const [fullName,setFullName] = useState(localStorage.getItem('user_full_name') || '');
  const [userID,setUserID] = useState(localStorage.getItem('user_id') || '');


  const { id } = useParams();
  console.log("The workout id is : ",id)

  const dayOrder = {
    "Monday": 1,
    "Tuesday": 2,
    "Wednesday": 3,
    "Thursday": 4,
    "Friday": 5,
    "Saturday": 6,
    "Sunday": 7,
  };
  


 


    useEffect(() => {
      console.log('WorkoutPlans.js useEffect called');


      // Check if workout data is available in local storage
      const localWorkoutData = JSON.parse(localStorage.getItem('userWorkoutData'));
      const last_selected_day = localStorage.getItem('last_selected_day') || 'Day 1';
      console.log('localWorkoutData :', localWorkoutData);
      const sessionObj = JSON.parse(localStorage.getItem('sb-ygcptpjdpipejtvnjnlg-auth-token'));

  
      setSelectedDay(last_selected_day);  // Set the default selected day from local storage or default to Day 1

      fetchData();
  
  
    }, []); 



    useEffect(() => {
      if (workoutData && workoutData.length > 0) {
        if (!workoutReplaced){
          
          setSelectedDay(workoutData[0].Day); // assuming each item in data has a 'day' property
        }
        setWorkoutReplaced(false)
      }
  }, [workoutData]);


  
  



  const fetchData = async () => {
    const sessionObj = JSON.parse(localStorage.getItem('sb-ygcptpjdpipejtvnjnlg-auth-token'));
    setIsLoading(true);

    try {
      const response = await fetch('https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/get_saved_data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ table: 'saved_workouts',workout_id:id })
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        console.log('the workout data is :');
        const workout_data = data.workout_plan;
        console.log(workout_data);
        setWorkoutData(workout_data);
        setUserExperienceLevel(data.user_experience_level);
        setUserFitnessGoal(data.user_fitness_goal);
        setUserPreferredWorkoutDays(data.user_preferred_workout_days);
        setUserWorkoutType(data.user_workout_type);

         

        setIsLoading(false);
    })
      .catch((error) => {
          console.error('Error:', error);
          setIsLoading(false);
      });


    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
}
  

  // const workoutsForSelectedDay = selectedDay ? workoutData.filter(workout => workout.Day === selectedDay) : [];

  const getUniqueBodyPartsForDay = (day) => {
    const workoutsForDay = workoutData.filter(workout => workout.Day === day);
    var bodyParts = [...new Set(workoutsForDay.map(workout => workout['Body Part Targeted']))];
    console.log(`bodyParts for day ${day}:`, bodyParts);
    return bodyParts;
  };

  const displayDayName = (day) => {
    if (!day) return '';  // Return an empty string if day is undefined

    // Check if the day is a rest day
    if (day.toLowerCase().includes('rest')) {
        return "Rest Day";
    }
    return day;
  }



    // Helper function to generate a unique ID
  const generateUniqueId = () => {
    return `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
  }



  const handleSignup = () => {
    navigate('/signup');
  };
  
  const SignupAlert = () => {
    dispatchAlert(dispatch, "alert", (
      <>
        To generate a customized diet plan, workout plan, 24/7 AI personal trainer all taylored to you,{' '}
        <span className="text-blue-500 underline" onClick={handleSignup}>
          Sign up
        </span>{' '}
        for a 14-day free trial of GetFitter.AI.
      </>
    ))
  }

  


  if (isLoading) {
    return (
        <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-4 space-y-4">
            <h1 className="text-xl mt-2 leading-none sm:text-2xl md:text-3xl lg:text-4xl tracking-tight font-bold text-gray-100 dark:text-white text-center">
                Your Workout Plan
            </h1>
            <svg className="animate-spin h-5 w-5 text-white mb-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <p className="text-base sm:text-lg md:text-xl text-gray-100 dark:text-white text-center">Loading public workout plan, please wait for few seconds...</p>
        </div>
    );
}

return (
  <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-1 sm:p-2 space-y-1 sm:space-y-2">
    <h1 className="text-base mt-1 leading-snug sm:text-2xl md:text-3xl lg:text-4xl tracking-tight font-bold text-gray-100 dark:text-white text-center">
      GetFitterAI's workout Plan
    </h1>
    <div className="text-base text-gray-400 text-center mb-4 w-full sm:w-1/2 lg:w-1/3 xl:w-1/3">

    <p>
  {showFullDetails ? (
    <>
      This workout plan is tailored for a user with an experience level of <span className="font-bold text-white">{userExperienceLevel}</span>. Who like to workout at the <span className="font-bold text-white">{userWorkoutType.split(" ")[0] }</span>. Their fitness goal is to <span className="font-bold text-white">{userFitnessGoal}</span>. They prefer to workout on <span className="font-bold text-white">{userPreferredWorkoutDays.replace(/[\[\]"]+/g, '')}</span>.
      <button onClick={() => setShowFullDetails(false)} className="text-white underline">
        <IoIosArrowDropupCircle className="inline-block align-text-bottom" size={24}/>
      </button>
    </>
  ) : (
    <>
      This workout plan is tailored for a user with an experience level of <span className="font-bold text-white">{userExperienceLevel}</span>. Their fitness goal is to <span className="font-bold text-white">{userFitnessGoal}</span>.
      <button onClick={() => setShowFullDetails(true)} className="text-white underline">
        <IoIosArrowDropdownCircle className="inline-block align-text-bottom" size={24} />
      </button>
    </>
  )}
</p>
    </div>




    {isLoading ? (
        <p className="text-sm sm:text-base md:text-lg text-gray-300 text-center">Loading workout plans...</p>
    ) : (
        <div className="flex flex-wrap gap-2 sm:gap-4 mb-4 w-full justify-center">
      {
        [...new Set(workoutData.map(item => item.Day))]
          .sort((a, b) => dayOrder[a] - dayOrder[b])
          .map(dayNumber => (
          <button 
            key={dayNumber} 
            onClick={() => {
              setSelectedDay(dayNumber);
              localStorage.setItem('last_selected_day', dayNumber);
            }}
            className={`px-6 py-2 rounded-full ${selectedDay === dayNumber ? 'bg-gradient-to-r from-blue-700 via-blue-800 to-gray-900 text-white' : 'bg-slate-900 text-gray-300 hover:bg-slate-800'}`}
          >
            {displayDayName(dayNumber)}
          </button>
        ))
      }
    </div>
  )}

  {selectedDay && (
      <div className="workout-details bg-slate-900 p-2 sm:p-4 rounded-lg shadow-lg w-full space-y-1 sm:space-y-2 max-w-4xl">
          {
              getUniqueBodyPartsForDay(selectedDay).map(bodyPart => {
                  const workoutsForBodyPart = workoutData.filter(workout => workout.Day === selectedDay && workout['Body Part Targeted'] === bodyPart);
                  return (
                      <div key={bodyPart} className="bodypart-section flex flex-col sm:flex-row space-y-1 sm:space-y-0 sm:space-x-2 mb-4">
                          <h4 className="text-xl font-semibold mb-1 text-gray-100 w-2/5 sm:w-1/4">{bodyPart}</h4>
                          <div className="workouts flex-1 flex flex-wrap gap-2 sm:gap-3 justify-center sm:justify-start">
                              {
                                  workoutsForBodyPart.map((workout, workoutIndex) => {
                                    const itemStyle = "max-w-xs sm:w-1/2 lg:w-1/4 sm:max-w-full";  // max-w-xs for mobile and overriding with full width for larger screens
                                    if (workoutsBeingReplaced.includes(workout.id)) {
                                        return (
                                          <div key={workoutIndex} className={`workout-item ${itemStyle} border border-gray-700 bg-gray-800 rounded-md p-3 sm:p-6 hover:bg-gray-700 transition-colors duration-300 flex flex-col justify-center items-center`}>
                                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            </div>
                                        );
                                    } else {
                                        return (
                                          <div key={workoutIndex} className={`workout-item ${itemStyle} border border-gray-700 bg-gray-800 rounded-md p-2 sm:p-4 hover:bg-gray-700 transition-colors duration-300 flex flex-col justify-start`}>
                                            <h5 className="text-base sm:text-lg font-medium mb-1 text-white">{workout['Workout Name']}</h5>
                                              <p className="mb-1 text-xs sm:text-sm text-gray-300"><strong className="font-semibold text-gray-100">No of Sets:</strong> {workout['No of Sets']}</p>
                                              <p className="mb-1 text-xs sm:text-sm text-gray-300"><strong className="font-semibold text-gray-100">No of Reps:</strong> {workout['No of Reps'] || workout['No of Reps ']}</p>
          
                                              <div className="flex justify-center items-center mt-1 sm:mt-2">
                                              <img 
                                                  src={youtube} 
                                                  alt="Watch on YouTube" 
                                                  onClick={() => window.open(workout['Youtube Video Link'], '_blank')} 
                                                  className="w-10 h-6 mr-1 sm:mr-2 cursor-pointer hover:opacity-80 transition-opacity duration-300"
                                              />
                                                {!(workout['Workout Name'] === 'Warmup' || workout['Workout Name'] === 'Cooldown') && 
                                                              <button onClick={() => SignupAlert()} className="px-2 sm:px-3 py-0.5 sm:py-1 text-xs sm:text-sm bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors duration-300">Replace</button>
                                                            }                                              </div>
                                          </div>
                                      );
                                  }
                              })
                            }
                            </div>
                          </div>
                      );
                  })
                }
                <div className="w-full flex justify-center mt-12">
                    <button 
                        onClick={() => SignupAlert()} 
                        className="mt-6 bg-gradient-to-r from-pink-500 to-purple-600 text-white py-2 px-6 rounded-full hover:bg-purple-700 focus:outline-none shadow-md"
                    >
                        Create my custom workout plan
                    </button>
                </div>

          </div>
          
          
          
        )}
        {showProOverlay && (
            <ProUpgradeOverlay 
                isVisible={showProOverlay} 
                onClose={() => setShowProOverlay(false)} 
                onUpgrade={() => {
                    // Handle the logic for upgrading to Pro here
                    setShowProOverlay(false);
                }}
            />
        )}

          
      </div>
    );



}  


export default SavedWorkouts;