import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BiSolidBowlHot } from "react-icons/bi";
import { MdOutlineFitnessCenter } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { HiDocumentCheck } from "react-icons/hi2";

const BottomNavbar = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    const getIconSize = (path) => {
        return currentPath === path ? 30 : 24;
    }

    const getIconColor = (path) => {
        return currentPath === path ? "text-blue-500" : "text-gray-100";
    }

    return (
        <div className="fixed bottom-0 left-0 right-0 bg-gray-900 p-2 flex justify-around items-center md:hidden shadow-2xl border-t border-gray-800 h-18">
            
            <Link to="/diet" className={getIconColor("/diet") + " flex flex-col items-center"}>
                <BiSolidBowlHot size={getIconSize("/diet")} />
            </Link>
            <Link to="/workouts" className={getIconColor("/workouts") + " flex flex-col items-center"}>
                <MdOutlineFitnessCenter size={getIconSize("/workouts")} />
            </Link>
            <Link to="/updatemyinfo" className={getIconColor("/updatemyinfo") + " flex flex-col items-center"}>
                <FaUser size={getIconSize("/updatemyinfo")} />
            </Link>
            <Link to="/beginnerguide" className={getIconColor("/beginnerguide") + " flex flex-col items-center"}>
                <HiDocumentCheck size={getIconSize("/beginnerguide")} />
            </Link>
        </div>
    );
};

export default BottomNavbar;
