import React, { useEffect, useState,useMemo } from 'react';
import breakfastImage from '../assets/meals/breakfast.jpeg';
import lunchImage from '../assets/meals/lunch.jpeg';
import dinnerImage from '../assets/meals/dinner.jpeg';
import snacksImage from '../assets/meals/snacks.jpeg';
import { useNavigate } from 'react-router-dom';
import ProUpgradeOverlay from './Utils/Components/ProOverlay'; // Adjust the path accordingly
import dispatchAlert from './Utils/Functions/DispatchAlert'
import writeUserData from './Utils/Functions/WriteUserData'
import ReactGA from 'react-ga';


import { useSelector, useDispatch } from 'react-redux';


const Diet = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [userData, setUserData] = useState({});
  const [mealData, setMealData] = useState([]);
  const [itemsBeingReplaced, setItemsBeingReplaced] = useState([]);
  const dietItemsModifying = useSelector((state) => state.diet.dietItemsModifying);
  const [expandedMeal, setExpandedMeal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const user_goal_tdee = localStorage.getItem('user_goal_tdee');
  const user_tdee = localStorage.getItem('user_tdee');
  const [currentDay, setCurrentDay] = useState('1'); // New state to track the current day


  const [showProOverlay, setShowProOverlay] = useState(false);
  const [saveButtonLoading, SetSaveButtonLoading] = useState(false);

  const [email,setEmail] = useState(localStorage.getItem('user_email') || '');
  const [fullName,setFullName] = useState(localStorage.getItem('user_full_name') || '');
  const [userID,setUserID] = useState(localStorage.getItem('user_id') || '');

  var url = `https://getfitter.lemonsqueezy.com/checkout/buy/cab108d7-fd40-48e4-969d-a8feaeaf70a7?checkout[custom][user_id]=${userID}&checkout[email]=${email}&checkout[name]=${fullName}`


    const checkUserGoalTDEE = () => {
    console.log("in Diet screen checkUserGoalTDEE")
      const userGoalTDEEString = localStorage.getItem('user_goal_tdee');
    if (userGoalTDEEString && userGoalTDEEString !== 'null') {
      console.log('user_goal_tdee exists')
    }
    else {
      console.log('user_goal_tdee does not exist')
      navigate('goal');
    }
    }

    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const premiumStatus = urlParams.get('premium_zxy');
  
      if (premiumStatus === 'active') {
        localStorage.setItem('user_pro', 1);
        dispatchAlert(dispatch,'success',`Successfully upgraded to premium enjoy all the benefits!`)

      }
    }, []); 


    

    useEffect(() => {
      let checkInterval;
      const startOverlayTimer = () => {
        const dietFirstLoadCompleted = localStorage.getItem('dietFirstLoadCompleted');
        if (!dietFirstLoadCompleted) {
          localStorage.setItem('dietFirstLoadCompleted', 'true');
          // Start the 10-second timer for the overlay after confirming the data is valid
          setTimeout(() => {
            var isPro = ProIntercept();
            if (!isPro) {
              setShowProOverlay(true);
            }
          }, 10000); // Delay the overlay for 10 seconds
        } else {
          // For subsequent visits or if the data is immediately available, check Pro status immediately
          var isPro = ProIntercept();
          if (!isPro) {
            setShowProOverlay(true);
          }
        }
      };
    
      const fetchOrCheckMealData = () => {
        const mealData = localStorage.getItem(`userMealDataDay${currentDay}`);
        if (mealData) {
          // If we have valid mealData, proceed with the overlay logic
          clearInterval(checkInterval); // Stop checking for mealData
          startOverlayTimer();
        } else {
          // If there's no mealData, keep checking every 5 seconds
          if (!checkInterval) {
            checkInterval = setInterval(fetchOrCheckMealData, 5000);
          }
        }
      };
    
      fetchOrCheckMealData();
    
      // Cleanup function to clear the interval and any set timeouts if the component unmounts
      return () => {
        if (checkInterval) {
          clearInterval(checkInterval);
        }
      };
    }, [currentDay, dietItemsModifying]); // This effect depends on `currentDay` to decide when to run
    
    useEffect(() => {
      if (dietItemsModifying.length > 0) {
        // Logic to reload the diet plan from the local storage
        const storedMealData = localStorage.getItem(`userMealDataDay${currentDay}`);
        if (storedMealData) {
          setMealData(JSON.parse(storedMealData));
        }
      }
    }, [dietItemsModifying, currentDay]);
    

    useEffect(() => {
  
      // Function to send a custom event
      const trackConversion = () => {
        ReactGA.event({
          category: 'Button',
          action: 'Click',
          label: 'Signup', 
          send_to: 'AW-11454835762/lJTUCKzC_oIZELLYi9Yq' 
        });
      };
  
      // Call the tracking function
      trackConversion();
      localStorage.setItem('active_day', '1')

    }, []);
  



  useEffect(() => {
      // Check if mealData exists in local storage
      console.log('useEffect called')
      console.log(`userMealDataDay${currentDay}`) 
      console.log("Before checkUserGoalTDEE");
      checkUserGoalTDEE();
      console.log("After checkUserGoalTDEE");
      localStorage.setItem('active_day', currentDay)

      const storedMealData = localStorage.getItem(`userMealDataDay${currentDay}`);

      if (storedMealData) {
        console.log('storedMealData exists ')
        setMealData(JSON.parse(storedMealData));
      } else {
        const user_goal_tdee = localStorage.getItem('user_goal_tdee');
        console.log('storedMealData does not exist')
        if(user_goal_tdee){
          fetchData(currentDay,false);
        }
      }


  }, [currentDay]);





  const ProIntercept = () => {
    // Check if 'user_pro' exists in localStorage
    if (!localStorage.getItem('user_pro')) {
        localStorage.setItem('user_pro', '0');  // Set it to '0' if it doesn't exist
    }

    const userProValue = parseInt(localStorage.getItem('user_pro'), 10);
    return userProValue !== 0;
}





  const checkReplaceUsed = () => {

    let userReplacedValue = parseInt(localStorage.getItem('user_replaced'), 10);
  
    // If user_replaced doesn't exist, set it to 1
    if (isNaN(userReplacedValue)) {
      localStorage.setItem('user_replaced', '1');
      return false;
    } else if (userReplacedValue >= 3) {
      return true; // Exit the function early
    } else {
      // Increment the user_replaced value by 1
      userReplacedValue += 1;
      localStorage.setItem('user_replaced', userReplacedValue.toString());
  
      dispatchAlert(dispatch, "alert", 
      <>You've done {userReplacedValue}/3 free replacements  
          <a href={url} 
             target="_blank" 
             rel="noopener noreferrer" 
             className="text-blue-500 underline">
             Upgrade to Pro
          </a> 
       for unlimited meals and replacements and workouts</>
      );
        return false;
    }
  
  }

  const saveCurrentPlan = async (selectedDay) => {
    try {
      const sessionString = localStorage.getItem('sb-ygcptpjdpipejtvnjnlg-auth-token');
      const user_age = localStorage.getItem('user_age');
      const user_gender = localStorage.getItem('user_gender');
      const user_weight_in_kgs = localStorage.getItem('user_weight_in_kgs');
      const user_height_in_cm = localStorage.getItem('user_height_in_cm');
      const user_fitness_goal = localStorage.getItem('user_fitness_goal');
      const user_food_preference = localStorage.getItem('user_food_preference');
      const user_diet_preference = localStorage.getItem('user_diet_preference');
      const user_allergies = localStorage.getItem('user_allergies');
      const user_tdee = localStorage.getItem('user_tdee');
      const user_goal_tdee = localStorage.getItem('user_goal_tdee');
      const user_preferred_protein_sources = localStorage.getItem('user_preferred_protein_sources');
      const user_fav_food =localStorage.getItem('user_fav_food');
      const diet_one = localStorage.getItem(`userMealDataDay${selectedDay}`);

      const parsedTokenData = JSON.parse(sessionString);
      const user_id = parsedTokenData.user.id;
      const write_table = 'saved_diets';
      const payload = {

          user_age: parseInt(user_age),
          user_gender,
          user_weight_in_kgs: parseFloat(user_weight_in_kgs),
          user_height_in_cm: parseFloat(user_height_in_cm),
          user_fitness_goal,
          user_goal_tdee: parseFloat(user_goal_tdee),
          user_allergies,
          user_food_preference,
          user_tdee: parseFloat(user_tdee),
          user_diet_preference,
          user_preferred_protein_sources,
          user_fav_food,
          diet_one,
          write_table,
      };

      console.log(`saved plan called - ${selectedDay}`);
      console.log(payload);

      fetch('https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/write_user_data', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${parsedTokenData['access_token']}`
          },
          body: JSON.stringify(payload),
      })
      .then(response => response.json())
      .then(data => {
        console.log("diet data saved successfully")
        dispatchAlert(dispatch,'success',`Successfully saved meal plan!`)

    })
      .catch((error) => {
          console.error('Error:', error);
          dispatchAlert(dispatch,'error',`Error saving meal plan pls try again later!`)
      });

  } catch (err) {
      console.error('An error occurred:', err);
      dispatchAlert(dispatch,'error',`Error saving meal plan pls try again later!`)

  }


  }

  const fetchData = async (selectedDay,butonPress) => {
    if(butonPress){
      var isPro = ProIntercept();
      if(!isPro){
        setShowProOverlay(true)
        return;
      }
    }
    

    try {
        // Fetch values from local storage
        const sessionString = localStorage.getItem('sb-ygcptpjdpipejtvnjnlg-auth-token');
        const user_fitness_goal = localStorage.getItem('user_fitness_goal');
        const user_food_preference = localStorage.getItem('user_food_preference');
        const user_diet_preference = localStorage.getItem('user_diet_preference');
        const user_allergies = localStorage.getItem('user_allergies');
        const user_goal_tdee = localStorage.getItem('user_goal_tdee');
        const user_preferred_protein_sources = localStorage.getItem('user_preferred_protein_sources');
        const user_fav_food =localStorage.getItem('user_fav_food');

        // Parse the session data
        const parsedTokenData = JSON.parse(sessionString);
        const user_id = parsedTokenData.user.id;

        const updatedUserData = {
            user_fitness_goal,
            user_goal_tdee: parseFloat(user_goal_tdee),
            user_allergies,
            user_food_preference,
            user_tdee: parseFloat(user_tdee),
            user_diet_preference,
            user_preferred_protein_sources,
            user_fav_food,
            currentDay: selectedDay,
            buttonPress: butonPress
        };

        console.log(`fetchdata called - ${selectedDay}`);
        console.log(updatedUserData);

        setIsLoading(true);
        fetch('https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/openai_get_meal_plan', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${parsedTokenData['access_token']}`
            },
            body: JSON.stringify(updatedUserData),
        })
        .then(response => response.json())
        .then(data => {
          setMealData(data);
          localStorage.setItem(`userMealDataDay${selectedDay}`, JSON.stringify(data));
          setIsLoading(false);
      })
        .catch((error) => {
            console.error('Error:', error);
            setIsLoading(false);
        });

    } catch (err) {
        console.error('An error occurred:', err);
        setIsLoading(false);
    }
}




const groupedMeals = ["Breakfast", "Lunch", "Snacks", "Dinner"].map(mealType => {
  return {
      type: mealType,
      items: mealData.filter(item => {
          if (mealType === "Snacks") {
              return item.Meal === "Snack" || item.Meal === "Snacks";
          } else {
              return item.Meal === mealType;
          }
      })
  };
});




  const total = mealData.reduce((acc, meal) => {
    acc.weight += parseFloat(meal['Weight (g)']);
    acc.calories += Number.isInteger(parseFloat(meal['Calories'])) ? parseFloat(meal['Calories']) : 0;
    acc.protein += parseFloat(meal['Protein (g)']);
    acc.carbs += parseFloat(meal['Carbs (g)']);
    acc.fat += parseFloat(meal['Fat (g)']);
     
    return acc;
  }, { weight: 0, calories: 0, protein: 0, carbs: 0, fat: 0 });


  const toggleMealExpansion = (mealType) => {
    if (expandedMeal === mealType) {
      setExpandedMeal(''); // Close if it's currently open
    } else {
      setExpandedMeal(mealType); // Open the clicked one
    }
  };


  const handleIntakeToggle = (item) => {
      // Handle the logic for toggling the intake tracking for the given item.
  };
  



  const switchDay = (day) => { 
    var extractedDay = day.charAt(day.length-1);
    console.log(extractedDay)
    // Check the user_pro value from local storage and convert it to an integer
   

    if ((extractedDay === '2' || extractedDay === '3')) {
      var isPro = ProIntercept();
      if(!isPro){
        setShowProOverlay(true)
        return;
      }
    }

    setCurrentDay(extractedDay);
};



const replaceWholeItem = async (itemToReplace) => {
  var isPro = ProIntercept();
  if (!isPro){
    console.log("not a pro user")
    var replaceUsed =   checkReplaceUsed()
    if(replaceUsed){
        dispatchAlert(dispatch, "alert", <>You've reached the maximum number of replacements! <a href={url} className="text-blue-500 underline"> Upgrade to Pro </a> for unlimited meals and replacements and workouts</>);
        return;
    }
  }

  setItemsBeingReplaced(prevItems => [...prevItems, itemToReplace['id']]);
  const user_food_preference = localStorage.getItem('user_food_preference');
  console.log('replaceWholeItem called');
  console.log(itemsBeingReplaced);
  console.log(itemToReplace);
  const sessionString = localStorage.getItem('sb-ygcptpjdpipejtvnjnlg-auth-token');
  const sessionObj = JSON.parse(sessionString);
  const dietColumns = [
    "",
    "diet_one",
    "diet_two",
    "diet_three",
    "diet_four",
    "diet_five"
  ];
  const diet_day = dietColumns[parseInt(currentDay)];
  
  try {
    const response = await fetch('https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/replace_diet_item', { 
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionObj['access_token'] || sessionObj['provider_token']}`
      },
      body: JSON.stringify({
        itemToReplace: itemToReplace,
        userData: userData,
        current_diet: localStorage.getItem(`userMealDataDay${currentDay}`),
        column_name: diet_day,
        user_food_preference,
      })
    });

    const data = await response.json();

    // Convert the string representation of replacementItem to an actual object
    if (data.replacementItem) {
      const parsedReplacementItem = JSON.parse(data.replacementItem);
      console.log('parsedReplacementItem:', parsedReplacementItem);
      const updatedMealData = mealData.map(item => {
        if (item.id === itemToReplace.id) {
          return parsedReplacementItem;
        }
        return item;
      });
      setMealData(updatedMealData);
      setItemsBeingReplaced(prevItems => prevItems.filter(item => item !== parsedReplacementItem['id']));
      localStorage.setItem(`userMealDataDay${currentDay}`, JSON.stringify(updatedMealData));
      dispatchAlert(dispatch,'success',`Successfully replaced ${itemToReplace['Item']} with ${parsedReplacementItem['Item']} maintaning silimar calories and macros`)
    }
    } catch (error) {
      console.error('Error replacing item:', error);
    }
    setIsLoading(false);
  };

  const LoadingIcon = () => (
    <div className="flex items-center justify-center h-full  p-2  h-50">
      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
  );







  return (
    <div className="diet-content-wrapper relative">

      <div className="flex flex-col items-center min-h-screen bg-gradient-to-r from-slate-900 to-slate-800 p-4 space-y-6">
          <h1 className="text-3xl mt-4 font-bold text-white text-center">
              Your Diet Plan
          </h1>
  
          {user_tdee && user_goal_tdee && (
              <p className="text-base text-gray-400 text-center mb-4">
              Your current Calories: <span className="font-bold text-white">{user_tdee}</span> | Your target Calories Should Be Around: <span className="font-bold text-white">{user_goal_tdee}</span>
            </p>
            
          )}
  
          <div className="w-full sm:w-3/4 lg:w-1/2 xl:w-1/3 mx-auto space-y-6 bg-slate-900 p-6 shadow-xl rounded-lg">
              <div className="flex justify-between space-x-4 mb-4">
                  <button 
                      onClick={() => switchDay('Day1')} 
                      className={`py-2 px-4 text-white rounded ${currentDay === '1' ? "bg-gradient-to-r from-blue-700 via-blue-800 to-gray-900" : "bg-slate-800 hover:bg-gray-700 focus:outline-none"}`}
                  >
                      Plan 1
                  </button>
                  <button 
                      onClick={() => switchDay('Day2')} 
                      className={`py-2 px-4 text-white rounded ${currentDay === '2' ? "bg-gradient-to-r from-blue-700 via-blue-800 to-gray-900" : "bg-slate-800 hover:bg-gray-700 focus:outline-none"}`}
                  >
                      Plan 2
                  </button>
                  <button 
                      onClick={() => switchDay('Day3')} 
                      className={`py-2 px-4 text-white rounded ${currentDay === '3' ? "bg-gradient-to-r from-blue-700 via-blue-800 to-gray-900" : "bg-slate-800 hover:bg-gray-700 focus:outline-none"}`}
                  >
                      Plan 3
                  </button>
              </div>
            {isLoading ? (
                <div className="flex items-center space-x-2">
                    <LoadingIcon />
                    <span className="text-white">Curating your custom healthy meal plan, please wait it may take few minutes..</span>
                </div>
            ) : (
                <>
                    {groupedMeals.map(group => {
              const mealTotals = group.items.reduce((acc, currItem) => {
                acc.calories += Number.isInteger(parseFloat(currItem["Calories"])) ? parseFloat(currItem["Calories"]) : 0;
                acc.protein += parseFloat(currItem["Protein (g)"]);
                acc.carbs += parseFloat(currItem["Carbs (g)"]);
                acc.fat += parseFloat(currItem["Fat (g)"]);
                return acc;
              }, { calories: 0, protein: 0, carbs: 0, fat: 0 });
  
                return (
                  <div key={group.type} className="bg-slate-900 shadow mb-4 rounded-lg">
                    <button 
                      onClick={() => toggleMealExpansion(group.type)}
                      className={`text-xl font-semibold py-2 px-5 w-full text-left text-black focus:outline-none
                                  ${expandedMeal === group.type ? "" : "rounded-b-lg"}
                                  ${group.type === "Breakfast" ? "bg-yellow-300" : 
                                  group.type === "Lunch" ? "bg-green-300" :
                                  group.type === "Dinner" ? "bg-blue-300" :
                                  "bg-purple-300"} rounded-t-lg`}
                    >
                      <div className="flex justify-between text-black">
                        <span>{group.type}</span>
                        <span>{mealTotals.calories.toFixed(2)} Calories</span>
                      </div>
                      <div className="text-xs font-normal mt-1 text-black">
                        {mealTotals.protein.toFixed(2)}g protein, {mealTotals.carbs.toFixed(2)}g carbs, {mealTotals.fat.toFixed(2)}g fat
                      </div>
        
                      <div className="text-black">
                        {expandedMeal === group.type ? "▲" : "▼"}
                      </div>
                    </button>
        
                    {expandedMeal === group.type && (
                      <table className="w-full border-collapse py-2 px-5">
                        <tbody>
                        {group.items.map((item, index) => (
    <tr key={index} className="py-1">
      <td className="border p-2">
          <img
            src={
              group.type === "Breakfast" ? breakfastImage :
              group.type === "Lunch" ? lunchImage :
              group.type === "Dinner" ? dinnerImage :
              group.type === "Snacks" ? snacksImage : null
            }
            alt={item.Item}
            width="40"
            height="40"
            className="rounded"
          />
      </td>
      <td className="border p-2 text-white">
        {dietItemsModifying.includes(item.id) ? (
          <LoadingIcon />
        ) : (
          <>
            <div className="font-bold">{item["Item"]}</div>
            <div>{item["Weight (g)"]}g, {item["Calories"]} cal</div>
            <div>{item["Protein (g)"]}g protein, {item["Carbs (g)"]}g carbs, {item["Fat (g)"]}g fat</div>
          </>
        )}
      </td>
      <td className="border p-2 text-center h-10">
        {itemsBeingReplaced.includes(item.id) ? (
          <LoadingIcon />
        ) : (
          <div className="flex items-center justify-center h-full">
            <button onClick={() => replaceWholeItem(item)} className="py-1 px-2 bg-slate-700 text-white rounded hover:bg-slate-600 focus:outline-none">Replace</button>
          </div>
        )}
      </td>
    </tr>
  ))}

                        </tbody>
                      </table>
                    )}
                  </div>
                );
              })}

                    {/* Total card */}
                    <div className="bg-red-300 shadow p-2 rounded-lg w-full mb-4">
                        <div className="text-xl font-semibold flex justify-between px-5 pr-3">
                            <span>Total</span>
                            <span>{total.calories.toFixed(2)} Calories</span>
                        </div>
                        <div className="text-xs font-normal px-5">
                            {total.protein.toFixed(2)}g protein, {total.carbs.toFixed(2)}g carbs, {total.fat.toFixed(2)}g fat
                        </div>
                    </div>

                    <div className="w-full flex justify-center mt-6 space-x-4">
                    <button
                      onClick={() => fetchData(currentDay, true)}
                      className="bg-gradient-to-r from-pink-500 to-purple-600 text-white py-2 px-6 rounded-full hover:from-pink-600 hover:to-purple-700 focus:outline-none shadow-md transition duration-300 ease-in-out"
                    >
                      {"Reset Day " + currentDay + " meal plan"}
                    </button>
                    (
                      <button
                        onClick={() => saveCurrentPlan(currentDay)}
                        className="bg-gradient-to-r from-pink-500 to-purple-600 text-white py-2 px-6 rounded-full hover:from-pink-600 hover:to-purple-700 focus:outline-none shadow-md transition duration-300 ease-in-out"
                      >
                        {"Save"}
                      </button>
                    )
                  </div>

                </>
          )}

        </div>
        {showProOverlay && (
          <ProUpgradeOverlay 
            isVisible={showProOverlay} 
            onClose={() => setShowProOverlay(false)} 
            onUpgrade={() => {
              // Handle the logic for upgrading to Pro here
              setShowProOverlay(false);
            }}
          />
        )}
        </div>
    </div>
);


}

export default Diet;