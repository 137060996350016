import React, { useState, useRef, useEffect } from 'react';
import './ChatWidget.css';
import aiProfileImage from '../../assets/main/trainer.png';
import typingGif from '../../assets/main/typing.gif';
import { useSelector, useDispatch } from 'react-redux';
import { setDietItemsModifying, addDietItem, removeDietItem } from '../../features/dietIteamModifyingSlice';
import {marked} from 'marked';

const ChatWidget = () => {
  const dispatch = useDispatch();

  const defaultAssistantGreeting = [
    { 
      sender: 'assistant', 
      content: 'Hello, this is Alex, your personal AI trainer. Ask me anything about fitness!' 
    }
  ];
  
  const storedMessages = JSON.parse(localStorage.getItem('user_chat_messages')) || defaultAssistantGreeting;
  const [messages, setMessages] = useState(storedMessages);
  const [isChatOpen, setIsChatOpen] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [isRequestProcessing, setIsRequestProcessing] = useState(false);
  const [isAssistantTyping, setIsAssistantTyping] = useState(false);

  useEffect(() => {
    localStorage.setItem('user_chat_messages', JSON.stringify(messages));
  }, [messages]);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

      // Helper function to generate a unique ID
      const generateUniqueId = () => {
        return `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
      }

  const addWarmupCooldownToWorkoutData = (data) => {
    const uniqueDays = [...new Set(data.map(item => item.Day))];

  const warmupTemplate = {
    "id": "",
    "Day": "",
    "Workout Name": "Warmup",
    "Body Part Targeted": "Warmup",
    "Youtube Video Link": "https://www.youtube.com/watch?v=divaflydT7M",
    "No of Sets": "1",
    "No of Reps": "1"
  };

  const cooldownTemplate = {
    "id": "",
    "Day": "",
    "Workout Name": "Cooldown",
    "Body Part Targeted": "Cooldown",
    "Youtube Video Link": "https://www.youtube.com/watch?v=Qy3U09CnELI",
    "No of Sets": "1",
    "No of Reps": "1"
  };

  const newData = [];

  uniqueDays.forEach(day => {
    // Add warmup for current day
    const warmup = { ...warmupTemplate, Day: day, id: generateUniqueId() };
    newData.push(warmup);

    // Add original workouts for current day
    const dayWorkouts = data.filter(workout => workout.Day === day);
    newData.push(...dayWorkouts);

    // Add cooldown for current day
    const cooldown = { ...cooldownTemplate, Day: day, id: generateUniqueId() };
    newData.push(cooldown);
  });

  return newData;
  }


  useEffect(scrollToBottom, [messages]);

  const handleSendMessage = (message) => {
    setMessages(prevMessages => [
      ...prevMessages,
      { sender: 'user', content: message }
    ]);
    setInputValue('');
    setIsAssistantTyping(true); // Start showing typing animation
    setIsRequestProcessing(true);

    const payload = {
      user_full_name: localStorage.getItem('user_full_name'),
      user_age: localStorage.getItem('user_age'),
      user_gender: localStorage.getItem('user_gender'),
      user_goal_tdee: localStorage.getItem('user_goal_tdee'),
      user_preferred_workout_days: localStorage.getItem('user_preferred_workout_days'),
      user_height_in_cm: localStorage.getItem('user_height_in_cm'),
      user_weight_in_kgs: localStorage.getItem('user_weight_in_kgs'),
      user_experience_level: localStorage.getItem('user_experience_level'),
      user_workout_type: localStorage.getItem('user_workout_type'),
      user_food_preference: localStorage.getItem('user_food_preference'),
      user_diet_preference: localStorage.getItem('user_diet_preference'),
      user_allergies: localStorage.getItem('user_allergies'),
      user_goal_tdee: localStorage.getItem('user_goal_tdee'),
      active_day: localStorage.getItem('active_day'),
      user_message: message
    };

    const parsedTokenData = JSON.parse(localStorage.getItem('sb-ygcptpjdpipejtvnjnlg-auth-token'));

    // Display patience message if response takes more than 10 seconds
    const patienceMessageTimeout = setTimeout(() => {
      setMessages(prevMessages => [
        ...prevMessages,
        { sender: 'assistant', content: 'Please be patient while I update your plan, which can take up to a minute!' }
      ]);
    }, 10000);

    fetch('https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/openai_chat_trainer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${parsedTokenData['access_token']}`
      },
      body: JSON.stringify(payload),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      return response.json();
    })
    .then(data => {
      clearTimeout(patienceMessageTimeout); // Clear the patience message timeout
      let messageContent;

      if (typeof data.message === 'string') {
        console.log("data.message is string")
        messageContent = data.message;
      } else if (data.message && typeof data.message === 'object') {
        console.log("data.message is object")
        console.log(data.message)
        messageContent = data.message.message;
        if (data.message.item_ids_updated) {
          console.log("item_ids_updated is present", data.message.item_ids_updated)
          let item_ids = data.message.item_ids_updated;
          let index = 0;

          function addAndRemoveItems() {
            if (index < item_ids.length) {
              let item = item_ids[index];
              dispatch(setDietItemsModifying([item]));

              setTimeout(() => {
                dispatch(removeDietItem(item));
                index++;
                addAndRemoveItems(); // Schedule the next add and remove
              }, 2000);
            }
          }

          addAndRemoveItems(); // Start the process
        }
        
        if(data.message.active_day){
          console.log("active_day is present, updating local storage dietplan")
          let diet_day_list = ['','diet_one','diet_two','diet_three','diet_four','diet_five','diet_six','diet_seven']
          let index = diet_day_list.indexOf(data.message.active_day.toString());

          
          let userDataUpdating = "userMealDataDay" + index.toString();
          localStorage.setItem(userDataUpdating, JSON.stringify(data.message.updated_plan));
        }
        else{
          console.log("active_day is not present, updating local storage workout")
          var updatedWorkoutData  = addWarmupCooldownToWorkoutData(data.message.updated_plan.workout_plan);
          localStorage.setItem('userWorkoutData', JSON.stringify(updatedWorkoutData));
        }
      } else {
        console.error("Unexpected data.message format");
        return;
      }

      setMessages(prevMessages => [...prevMessages, { sender: 'assistant', content: messageContent }]);
      setInputValue('');
    })
    .catch(error => {
      console.error("Error handling data:", error);
    })
    .finally(() => {
      setIsRequestProcessing(false);
      setIsAssistantTyping(false);
    });
  };

  return (
    <div>
      <div className={`chat-widget ${isChatOpen ? 'open' : ''}`}>
        <div className="chat-header">Chat with your 24/7 personal trainer</div>
        <div className="chat-content">
          {messages.map((message, index) => (
            <div className={`chat-message ${message.sender}`} key={index}>
              {message.sender === 'assistant' && (
                <img src={aiProfileImage} alt="AI" className="ai-profile-image" />
              )}
              <div 
                className="message-bubble" 
                dangerouslySetInnerHTML={{ __html: marked(message.content) }}
              ></div>
            </div>
          ))}
          {isAssistantTyping && (
            <div className="chat-message assistant">
              <img src={aiProfileImage} alt="AI" className="ai-profile-image" />
              <img src={typingGif} className="typing-gif" alt="Assistant typing..." />
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <div className="chat-input">
          <input
            type="text"
            placeholder="Ask me anything about fitness"
            value={inputValue}
            disabled={isRequestProcessing}
            onChange={e => setInputValue(e.target.value)}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                handleSendMessage(inputValue);
              }
            }}
          />
          <button onClick={() => handleSendMessage(inputValue)}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default ChatWidget;
